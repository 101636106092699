import config from '../config/site.config';

export const storeService = {
    getStore,
};

function teste(a){
	console.log("teste");
	console.log(a);
}

// componente no formato de classe
function getStore(storename) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    //return fetch(`http://localhost:3333/store?name=`+storename, requestOptions)
    return fetch(`${config.apiUrl}/store?name=${storename}`, requestOptions)
        .then(handleResponse)
        .then(store => {

            if (store) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                //user.authdata = window.btoa(email + ':' + password);
                localStorage.setItem('store', JSON.stringify(store));
                localStorage.setItem('store-token', store._id);
				return store;
            }
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
		console.log(data);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
				console.log("response.status == 401");
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
