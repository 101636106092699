class AuthAdmin {
	user = null;

	constructor() {
		this.user = JSON.parse(localStorage.getItem('admin'));

		this.getProfile = this.getProfile.bind(this);
		//this.handleAuthentication = this.handleAuthentication.bind(this);
		//this.isAuthenticated = this.isAuthenticated.bind(this);
		//this.signIn = this.signIn.bind(this);
		//this.signOut = this.signOut.bind(this);
	}

	getProfile() {
		return this.user;
		//return this.profile;
	}

	getIdToken() {
		//return this.idToken;
	}

	isAuthenticated() {
		return (this.user ? true : false);
	}

	signIn() {
		//this.auth0.authorize();
	}

	signOut() {
		console.log("signOut");
		localStorage.removeItem('admin');
		localStorage.removeItem('admin-auth-token');
	}
}

const authAdmin = new AuthAdmin();

export default authAdmin;
