
import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SubHeader from '../../components/subheader/SubHeader';
import { storeService } from '../../services/store.service';
import { Row, Col, ModalFooter } from "shards-react";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import auth from '../../_helpers/auth';
import Moment from 'react-moment';
import config from '../../config/site.config.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCheckCircle, faGripLines } from '@fortawesome/free-solid-svg-icons';

import api from '../../services/api';
import "./Orders.css";

import {
	Badge,
	Card,
	CardBody, 	
  } from "shards-react";

class Orders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
			store: { },
			loading: false,
			error: false,
        orders: {
          'new': [],		
          'old': []
        }
      };
	}

    async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
		await this.loadOrders(auth.user._id);
	}

	async loadOrders(userid){
    const response = await api.get(`orders/${userid}`);
    const orders_all = response.data;

    let orders = {
      'new': [],
      'old': []
    }

    orders_all.map(function(item, idx) {
      if((item.status != 'complete') && (item.status != 'canceled')){
        orders['new'].push(item);
      } else {
        orders['old'].push(item);
      }
    });

    console.log("orders");
    console.log(orders.old);

    this.setState({ orders });
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					this.setState({ store: store });
				},
				error => console.log(error)
			);
		this.setState({ loading: false });
	}

	showStatus = (status) => {
		let newstatus = "";
		// pending | confirmed | canceled | delivery | complete
		switch(status){
			case "pending":
				newstatus = <Badge className="badge-status">Aguardando confirmação</Badge>;
				break;
			case "confirmed":
				newstatus = <Badge className="badge-status">Confirmado</Badge>;
				break;
			case "canceled":
				newstatus = <Badge className="badge-status">Cancelado</Badge>;
				break;
			case "delivery":
				newstatus = <Badge className="badge-status">Saiu para entrega</Badge>;
				break;
			case "complete":
				newstatus = <Badge className="badge-status">Finalizado</Badge>;
				break;
		}
		return newstatus;
	} 

    render() {
        const { store } = this.state;
        return (
			<div id="orders" className="content">
        <Header store={this.state.store} 
                logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`}
        />
        <SubHeader title="Pedidos" />


        <Card>
          <CardBody className="card-body">
            { this.state.orders.old.length < 1 && this.state.orders.new.length < 1 &&
              <div className="no-order">Você ainda não fez nenhum pedido</div>
            }

            { this.state.orders.new.length > 0 &&
              <div className="pedidos">
                <div className="pedidos-title">Pedidos Ativos</div>
                {this.state.orders.new.map((order, idx) => (

                  <Link to={`/order/${order._id}`}>
                    <div className="list-order-item-new">

                        <div className="list-order-content">
                          <div className="list-order-new-top">
                            <div className="list-order-new-date">
                             <Moment format="DD/MM/YY HH:mm:SS">{order.createdAt}</Moment>
                            </div>
                            <div className="list-order-new-amount">
                              Total: {parseFloat(order.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                            </div>
                          </div>

                          <div className="list-order-internal">
        
                            <div className="recebido">
                              <div className="status-icon active">
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </div>
                              <div className="status-text">Recebido</div>
                            </div>

                            <div className="space"><FontAwesomeIcon icon={faGripLines} /></div>

                            <div className="confirmado">
                              <div className={`status-icon ${((order.status == 'confirmed') || (order.status == 'delivery')) ? "active" : ""}`}>
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </div>
                              <div className="status-text">Confirmado</div>
                            </div>

                            <div className="space"><FontAwesomeIcon icon={faGripLines} /></div>

                            <div className="enviado">
                            <div className={`status-icon ${(order.status == 'delivery') ? "active" : ""}`}>
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                              <div className="status-text">Enviado</div>
                            </div>
                          </div>
      
                        </div>
                        
                        <div className="list-order-seta">
                          <FontAwesomeIcon icon={faChevronRight} className="btBackIcon" />
                        </div>

                      </div>
                  </Link>
                ))}
  
              </div>
            }

            { this.state.orders.old.length > 0 &&
              <div className="pedidos-old">
                <div className="pedidos-title">Pedidos Antigos</div>
                {this.state.orders.old.map((order, idx) => (

                  <Link to={`/order/${order._id}`}>
                    <div className="list-order-item">
                      <div className="list-order-date">
                        <Moment format="DD">{order.createdAt}</Moment><br/>
                        <Moment format="MMM">{order.createdAt}</Moment>
                      </div>
                      <div className="list-order-description">
                        <div className="list-order-amount">Total: {parseFloat(order.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </div>
                        <div class="list-order-status">{ this.showStatus(order.status) }</div>
                      </div>
                      <div className="list-order-seta">
                        <FontAwesomeIcon icon={faChevronRight} className="btBackIcon" />
                      </div>
                    </div>
                  </Link>
                ))}
  
              </div>
            }
          </CardBody>
        </Card>

        <Footer />
      </div>
    );
  }
}

export default Orders;
