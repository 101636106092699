import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './SubHeaderHome.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class SubHeaderHome extends Component {

  

  constructor(props){
    super(props);
    this.state = {
      cartQtd: 0,
    }
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  handleForward = () => {
    console.log(this.props.history)
    this.props.history.go(+1)
  }

  getCartItemsQtd(){
		const cart = localStorage.getItem(`${this.props.store.name}_cart`);
		if(cart){
      const cartJson = JSON.parse(cart);
      if(cartJson.products.length){
        return cartJson.products.length;
      } else { 
        return 0;
      }
		}
	}
  
  getCartBubble(){
    let qtd = this.getCartItemsQtd();
    if(qtd > 0){
      return <span className="cartNumber">{ qtd}</span>;
    } else {
      return '';
    }
  }

  render() {
    return  <div className="subheaderhome-content">
          <div className="title">{ this.props.store.title } </div>
          <div className="icons">
            <div className="icon-div">
              <Link to="/info">
                <FontAwesomeIcon icon={faInfoCircle} className="icon-info" />
              </Link>
            </div>
            <div className="icon-div">
              <Link to="/cart">
                <FontAwesomeIcon icon={faShoppingCart} className="icon-cart" />
                { this.getCartBubble() }
              </Link>   
            </div>
          </div>
      </div>
  }
}

export default withRouter(SubHeaderHome)