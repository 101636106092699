import React from "react";
//import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

const ExternalLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col
        className="main-content p-0"
        lg={{ size: 12 }}
        md={{ size: 12 }}
        sm="12"
        tag="main"
      >
        {children}
      </Col>
    </Row>
  </Container>
);

ExternalLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: true,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: true
};

ExternalLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default ExternalLayout;
