import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './SubHeader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

class SubHeader extends Component {

  handleBack = () => {
    this.props.history.goBack()
  }

  handleForward = () => {
    console.log(this.props.history)
    this.props.history.go(+1)
  }

  render() {
    return  <div className="subheader-content">
          <div className="btBack" onClick={this.handleBack}>
            <FontAwesomeIcon icon={faChevronLeft} className="btBackIcon" />
          </div>
          <div className="page-title">{ this.props.title}</div>
      </div>
  }
}

export default withRouter(SubHeader)