import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader  } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';

import adminApi from '../../services/admin.api';

import './Products.css';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Product extends Component {

	constructor(props) {
		super(props);

		this.state = {
			items: [],
			edit: false,
			showAlert: false,
			removeItemId: null,
			idx: null,
		};
	}

    async componentDidMount(){
		await this.loadData();
	}

	async loadData(){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
	        const response = await adminApi.get('admin/product/'+storeid);
			this.setState({ items: response.data });
		}
	}

	saveHandler = async () => {
		let currentItem = this.state.currentItem;
 		await adminApi.put(`admin/product/${currentItem._id}`, currentItem);
		this.closeModalHandler();
		await this.loadData();
	}

    handleRemove = (idx, id) => {
		this.setState( { removeItemId: id, idx: idx });
		this.toggleAlert();
	}

	remove = () => {
		this.toggleAlert();
		adminApi.delete(`admin/product/${this.state.removeItemId}/`);
		let array = this.state.items;
		array.splice(this.state.idx, 1);
		this.setState({items: array});
	}
		
	// alert
	toggleAlert = () =>{
		this.setState( {showAlert: !this.state.showAlert }); 
	}
	cancelAlert = () => {
		this.setState( { removeItemId: null, idx: null });
		this.toggleAlert();
	}

	render(){
		const {
			items,
			currentItem
		} = this.state;

		return (
			 <Container fluid className="main-content-container px-6">

				<Card small className="mb-4 content">

					<CardHeader className="p-0 pb-3">
						<Row>
							<Col sm="10">
								<div className="title">
									<h5 className="m-0">
										<FontAwesomeIcon icon={faShoppingBasket} style={{marginRight: '7px'}}/>
										Produtos</h5>
								</div>
							</Col>
							<Col sm="2">
								<div className="btAdd">
									<a href="/admin/product">
										<button className="btn btn-primary btn-sm" type="button">
											<FontAwesomeIcon icon={faPlus} />
										</button>
									</a>
								</div>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="p-0 pb-3">
						<table className="table mb-0">
							<thead className="bg-light">
								<tr>
									<th scope="col" style={{width: '30%'}} className="border-1">Nome</th>
									<th scope="col" style={{width: '10%'}} className="border-1">Preço</th>
									<th scope="col" style={{width: '30%'}} className="border-1">Categoria</th>
									<th scope="col" style={{width: '15%'}} className="border-1">Em estoque</th>
									<th scope="col" style={{width: '15%'}} className="border-1 acoes">Ações</th>
								</tr>
							</thead>
							<tbody>
								{items.map((prod, idx) => (
									<tr key={idx}>
										<td>{prod.name}</td>
										<td>{parseFloat(prod.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
										<td>{prod.category.name}</td>
										<td>{prod.active ? "Estoque" : "Fora de estoque"}</td>
										<td className="acoes">
											<Link to={`/admin/product/${prod._id}`}>
												<FontAwesomeIcon icon={faEdit} className="acoes edit"/>
											</Link>
											<Link onClick={() => this.handleRemove(idx, prod._id)}>
												<FontAwesomeIcon icon={faTrash} className="acoes remove" />
											</Link>
										</td>
									</tr>
							))}
							</tbody>
						</table>
					</CardBody>
				</Card>

				<SweetAlert
					show={this.state.showAlert}
					warning
					showCancel
					confirmBtnText="Sim, quero remover!"
					confirmBtnBsStyle="danger"
					title="Tem certeza?"
					onConfirm={this.remove}
					onCancel={this.cancelAlert}
					focusCancelBtn
					btnSize="sm">
					Você não será capaz de recuperar o item removido.
				</SweetAlert>

			</Container>

		);
	}
}

export default Product;
