import React from 'react';
import { BrowserRouter as Router, Route  } from 'react-router-dom';
import Routes from './routes';
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
/*
function App() {
  return (
    <BrowserRouter>
        <Header />
		<NavBar/>
        <Routes />
    </BrowserRouter>
  );
}
export default App;
*/

export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      {Routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
  </Router>
);
