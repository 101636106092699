import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Badge, Button, CardTitle  } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import Moment from 'react-moment';

import adminApi from '../../services/admin.api';

import './OrdersDay.css';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Orders extends Component {

	constructor(props) {
		super(props);

		this.state = {
			items: [],
			edit: false,
			showAlert: false,
			idx: null,
		};
	}

    async componentDidMount(){
		await this.loadData();
	}

	async loadData(){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
	        const response = await adminApi.get('admin/order/'+storeid);
			this.setState({ items: response.data });
		}
	}

	/*
	saveHandler = async () => {
		let currentItem = this.state.currentItem;
 		await adminApi.put(`admin/order/${currentItem._id}`, currentItem);
		await this.loadData();
	}
	*/


	showStatus = (status) => {

		//let statues = [];

		let newstatus = "";
		// pending | confirmed | canceled | delivery | complete
		switch(status){
			case "pending":
				newstatus = <Badge>Novo</Badge>;
				break;
			case "confirmed":
				newstatus = <Badge theme="warning">Confirmado</Badge>;
				break;
			case "canceled":
				newstatus = <Badge theme="danger">Cancelado</Badge>;
				break;
			case "delivery":
				newstatus = <Badge theme="success">Saiu para entrega</Badge>;
				break;
			case "complete":
				newstatus = <Badge theme="secondary">Finalizado</Badge>;
				break;
		}
		return newstatus;
	} 

	handleChangeStatus = async (idx, id, newstatus) => {

		let items = [...this.state.items];
		let item = {...items[idx]};
		item.status = newstatus;
		items[idx] = item;

		await this.changeOrderStatus(item);

		this.setState({items});		
	}

	changeOrderStatus = async (order) => {
		await adminApi.put(`admin/order/status/${this.state.storeid}/${order._id}/`, { status: order.status} );
	} 

	render(){
		const {
			items,
			currentItem
		} = this.state;

		return (
			 <Container fluid className="main-content-container content px-6">
				<Row>
					<Col sm="10">
						<div className="title">
							<h5 className="title m-0">
								<FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '7px'}}/>
								Pedidos Ativos</h5>
						</div>
					</Col>
				</Row>

                <Row>
				<Col sm="12" md="4">
						<Card small className="content">
							<CardHeader className="p-0 pb-3">
								<CardTitle className="title">Novos Pedidos</CardTitle>
							</CardHeader>
							
							{items.filter(item => item.status === 'pending').map(item => (
								<CardBody className="p-0 pb-3 item">
									<div className="number">
										<Link to={`/admin/order/${item._id}`}>
											<Button pill theme="primary" size="sm">
												#{ item.number}
											</Button>
										</Link>		
									</div>
									<div className="data">
										<div className="horario"><Moment format="DD/MM/YYYY HH:mm">{item.createdAt}</Moment></div>
										<div className="cliente">{item.user.name} {item.user.lastname}</div>
										<div className="valor">
											{parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
										</div>
									</div>
									<div className="btView">
										<Link to={`/admin/order/${item._id}`}>
											<Button className="btn-detalhes" size="sm">
												Detalhes
											</Button>
										</Link>	
									</div>
								</CardBody>
							))}
						</Card>					
					</Col>
                	<Col sm="12" md="4">
						<Card small className="content">
							<CardHeader className="p-0 pb-3">
								<CardTitle className="title">Confirmados</CardTitle>
							</CardHeader>
						
							{items.filter(item => item.status === 'confirmed').map(item => (
								<CardBody className="p-0 pb-3 item">
									<div className="number">
										<Link to={`/admin/order/${item._id}`}>
											<Button pill theme="warning" size="sm">
												#{ item.number}
											</Button>
										</Link>		
									</div>
									<div className="data">
										<div className="horario"><Moment format="DD/MM/YYYY HH:mm">{item.createdAt}</Moment></div>
										<div className="cliente">{item.user.name} {item.user.lastname}</div>
										<div className="valor">
											{parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
										</div>
									</div>
									<div className="btView">
										<Link to={`/admin/order/${item._id}`}>
											<Button className="btn-detalhes" size="sm">
												Detalhes
											</Button>
										</Link>	
									</div>
								</CardBody>
							))}
						</Card>					
					</Col>
                	<Col sm="12" md="4">
						<Card small className="content">
							<CardHeader className="p-0 pb-3">
								<CardTitle className="title">Em entrega</CardTitle>
							</CardHeader>
							{items.filter(item => item.status === 'delivery').map(item => (
								<CardBody className="p-0 pb-3 item">
									<div className="number">
										<Link to={`/admin/order/${item._id}`}>
											<Button pill theme="success" size="sm">
												#{ item.number}
											</Button>
										</Link>		
									</div>
									<div className="data">
										<div className="horario"><Moment format="DD/MM/YYYY HH:mm">{item.createdAt}</Moment></div>
										<div className="cliente">{item.user.name} {item.user.lastname}</div>
										<div className="valor">
											{parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
										</div>
									</div>
									<div className="btView">
										<Link to={`/admin/order/${item._id}`}>
											<Button className="btn-detalhes" size="sm">
												Detalhes
											</Button>
										</Link>	
									</div>
								</CardBody>
							))}
						</Card>					
					</Col>					

				</Row>
			</Container>

		);
	}
}

export default Orders;
