
import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SubHeader from '../../components/subheader/SubHeader';
import { storeService } from '../../services/store.service';
import { Container, Row, Col, Card, CardBody, CardHeader, FormCheckbox  } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash  } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SweetAlert from 'react-bootstrap-sweetalert';
import auth from '../../_helpers/auth';
import config from '../../config/site.config.js';

import {
	Form,
	FormInput,
	Button,
	Modal, ModalBody, ModalHeader, ModalFooter
  } from "shards-react";

import apiopen from '../../services/apiopen';
import api from '../../services/api';
import "./PaymentDelivery.css";

class PaymentDelivery extends React.Component {

	emptyItem = {
		name: '',
		address: '',
		number: '',
		complement: '',
		zipcode: '',
		neighborhood: '',
		city: '',
		state: '',
	}

    constructor(props) {
        super(props);
        this.state = {
			store: { },
			loading: false,
			showAlert: false,
			showAlertSuccess: false,
			error: false,
			selectedAddress: null,
			selectedPayment: null,
            cart: {
                products: [],
				comments: '',
				subtotal: '',
				delivery_type: '',
				delivery_price: '',
				total: ''
			},
			modalOpen: false,
			currentItem: this.emptyItem,
			user: auth.getProfile(),
            paymentMethods: [
                { code: 'din', title: 'Dinheiro', check: false},
                { code: 'cdm', title: 'Cartão de débito master', check: false},
                { code: 'cdv', title: 'Cartão de débito visa', check: false},
                { code: 'ccm', title: 'Cartão de crédito master', check: false},
                { code: 'ccv', title: 'Cartão de crédito visa', check: false},
            ]			
		};
		this.baseState = this.state;
	}

    async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
		await this.loadCart(this.state.subdomain);
		await this.loadAddressses(auth.getProfile()._id);
	}

	async loadCart(domain){
		const cart = localStorage.getItem(`${domain}_cart`);
		if(cart){
			await this.setState({
				cart: JSON.parse(cart)
			});
			this.calcPrices();
		}
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					this.setState({ store: store });

					// marca os meios de pagamento
					// organiza os meios de pagamento
					let payment_methods = [];
					let paymentMethods = this.state.paymentMethods;
					store.payment_methods.map((payment, idx) => {
						let item = paymentMethods.find((element) => {
							return element.code === payment;
						})
						if(item){
							item.check = true;
						}
					})   
					this.setState({paymentMethods});

					this.calcPrices();
				},
				error => console.log(error)
				//error => this.setState({ error, loading: false })
			);

		this.setState({ loading: false });
	}



	// adicionar produto no carrinho - fim
    // -----------------------------------------------------------------------	
    // carrinho de compras - inicio

    removeItemFromCart = async (idx, prod) => {
		let array = this.state.cart.products;
		array.splice(idx, 1);
        await this.setState(prevState => ({
			cart: { ...prevState.cart,  ['products']: array }
		}));
		localStorage.setItem(`${this.state.store.name}_cart`, JSON.stringify(this.state.cart));

		this.calcPrices();
	}
	
	calcPrices = async () => {
		const subtotal = this.state.cart.products.reduce((total, item) => total + item.subtotal, 0);
		let total = subtotal;

		if(this.state.store.delivery_type == 'pay'){
			total += this.state.store.delivery_price;
		}

        await this.setState(prevState => ({
			cart: { ...prevState.cart,  ['subtotal']: subtotal, ['total']: total }
		}));		
	}

    // carrinho de compras - fim
	// -----------------------------------------------------------------------
	// endereco - inicio

	// add
	handleAdd = async () => {
        await this.setState({
            modalOpen: true,
			edit: false,
        });
	}
	addAddressHandler = async () =>{
		let currentItem = this.state.currentItem;
		currentItem.userid = this.state.user._id;
 		await api.post(`address/${currentItem.userid}`, currentItem);
		this.closeModalHandler();
		await this.loadAddressses();
	}
	removeAddress = async (address) => {
		this.toggleAlert();
		await api.delete(`address/${this.state.removeItemId}/`);

		let array = this.state.addresses;
		array.splice(this.state.idx, 1);
		this.setState({addresses: array});
	}
	loadAddressses = async (userid) => {
		const response = await api.get(`address/${userid}`);
		this.setState({ addresses: response.data });
	}

	handleChange = e => {
		e.persist();
		this.setState(prevState => ({
			currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
		}));
	}
	// modal
    closeModalHandler = async () => {
        await this.setState({
            modalOpen: false,
			currentItem: this.emptyItem
        });
	}	

	handleRemoveAddress = (idx, id) => {
		this.setState( { removeItemId: id, idx: idx });
		this.toggleAlert();
	}
	// alert
	toggleAlert = () =>{
		this.setState( {showAlert: !this.state.showAlert }); 
	}
	cancelAlert = () => {
		this.setState( { removeItemId: null, idx: null });
		this.toggleAlert();
	}

	selectAddress = (idx, address) => {
		this.state.addresses.map((item, idx) => {
			item.selected = false;
		})   			
		address.selected = true;

		this.setState( { selectedAddress: address });
	}
	// endereco - fim
	// -----------------------------------------------------------------------
	// payment - inicio

	selectPayment = (idx, payment) => {
		this.state.paymentMethods.map((item, idx) => {
			item.selected = false;
		})   			
		payment.selected = true;

		this.setState( { selectedPayment: payment });
	}	
	// payment - fim
	// -----------------------------------------------------------------------		
	// order - inicio

	handleConfirmOrder = async () => {
		const order = {
			storeid: this.state.store._id,
			userid: this.state.user._id,
			delivery_address: this.state.selectedAddress,
			payment: this.state.selectedPayment,
			cart: this.state.cart
		}

		// envia o pedido
		const response = await api.post('order', order);

		// limpa o carrinho do state e do storage
		this.setState(this.baseState);

		let lastorder = response.data.id;
		this.setState({lastorder: lastorder});

		localStorage.removeItem(`${this.state.store.name}_cart`);

		// gera alerta de sucesso
		this.setState( { showAlertSuccess: true });
	}

	goToOrders = () => {
		this.setState( { showAlertSuccess: false });

		const { history } = this.props;
        history.push(`/order/${this.state.lastorder}`);
	}

	// order - fim
	// -----------------------------------------------------------------------	

    render() {
        const { store, user, currentItem, addresses, paymentMethods } = this.state;
        return (
          <div id="payment">
            <Header store={this.state.store} 
                    logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`}
            />
            <SubHeader title="Pedido" />

            <div className="h-100 no-gutters row">
              <div className="auth-form mx-auto my-auto col-sm-12">
                <div className="card">
                  <Row>
                    <Col>
                      <div className="card-body">
                        <h6 className="text-center">Selecione o endereço</h6>

                        { addresses && addresses.length > 0 &&
                          <div>
                          {addresses.map((address, idx) => (
                            <Row className="addressOut col-sm-12">
                              <Col md="12" className="address">
                                <div className='col-sm-12'>
                                  { address.selected && address.selected == true && 
                                    <span>Selecionado</span>
                                  }
                                  <Link onClick={() => this.selectAddress(idx, address)}>
                                    <div>{address.name}</div>
                                    <div>{address.address}, {address.number}</div>
                                    { address.complement &&
                                      <div>{address.complement}</div>
                                    }
                                    <div>{address.zipcode} - {address.neighborhood}</div>
                                    <div>{address.city} / {address.state}</div>
                                  </Link>	
                                  <Link onClick={() => this.handleRemoveAddress(idx, address._id)}>
                                    <FontAwesomeIcon icon={faTrash} className="removeButton"/>
                                  </Link>	
                                </div>
                              </Col>
                            </Row>
                          ))}                            
                          </div>
                        }
                        <div>
                          <div>Cadastre um novo endereço</div>
                          <Button type="button" onClick={this.handleAdd}>Cadastre um endereço</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card-body">
                        <h6 className="text-center">Selecione a forma de pagamento</h6>

                        { paymentMethods && paymentMethods.length > 0 &&
                          <div>
                          { paymentMethods.map((payment, idx) => (
                            payment.check == true && 
                              <Row className="paymentOut col-sm-12">
                                <Col md="12" className="payment">
                                  <div className='col-sm-12'>
                                    { payment.selected && payment.selected == true && 
                                      <span>Selecionado</span>
                                    }
                                    <Link onClick={() => this.selectPayment(idx, payment)}>
                                      <div>{payment.title}</div>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                          ))}                            
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>

                  { this.state.cart.products.length > 0 &&
                    <div>
                      {this.state.cart.products.map((prod, idx) => (
                        <Row className="productOut col-sm-12">
                          <Col md="12" className="product">
                            <div className='col-sm-12'>
                              <div>{prod.qtd} - </div>
                              <div>{prod.name}</div>
                              { prod.description &&
                                <div>{prod.description}</div>
                              }
                              { prod.comments &&
                                <div>Obs.: <i>{prod.comments}</i></div>
                              }
                              <div>{parseFloat(prod.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                              <div>{parseFloat(prod.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                              <Link onClick={() => this.removeItemFromCart(idx, prod)}>
                                <FontAwesomeIcon icon={faTrash} className="removeButton"/>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      ))}                            

                      <Row className="rowFinish">
                        <Link onClick={this.handleConfirmOrder}>
                          <Button type="button">Concluir Pedido</Button>
                        </Link>
                      </Row>
                    </div>
                  }

                            </div>
              </div>
            </div>

                    <Footer />

            <Modal open={this.state.modalOpen} toggle={this.closeModalHandler}>
              <ModalHeader >
                <Row md="12">
                <Col md='10'>
                  <span className="modal-title">
                    <span>Adicionar Endereço</span>
                  </span>
                </Col>
                <Col md='2'>
                  <span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
                </Col>
                </Row>
              </ModalHeader>
              
              <ModalBody>
                <Form id='categoryForm'>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label htmlFor="name">Nome</label>
                      <FormInput id="name" type="text" placeholder="Digite um apelido para o endereço,
                      como casa ou trabalho" value={currentItem.name}
                        onChange={this.handleChange}  />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8" className="form-group">
                      <label htmlFor="address">Rua</label>
                      <FormInput id="address" type="text" placeholder="Rua" value={currentItem.address}
                        onChange={this.handleChange}  />
                    </Col>
          
                    <Col md="4" className="form-group">
                      <label htmlFor="number">Número</label>
                      <FormInput id="number" type="text" placeholder="Número" value={currentItem.number}
                        onChange={this.handleChange}  />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="complement">Complemento</label>
                      <FormInput id="complement" type="text" placeholder="Complemento" value={currentItem.complement}
                        onChange={this.handleChange}  />
                    </Col>
                    
                    <Col md="6" className="form-group">
                      <label htmlFor="zipcode">CEP</label>
                      <FormInput id="zipcode" type="text" placeholder="CEP" value={currentItem.zipcode}
                        onChange={this.handleChange}  />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="5" className="form-group">
                      <label htmlFor="neighborhood">Bairro</label>
                      <FormInput id="neighborhood" type="text" placeholder="Bairro" value={currentItem.neighborhood}
                        onChange={this.handleChange}  />
                    </Col>
                    <Col md="5" className="form-group">
                      <label htmlFor="city">Cidade</label>
                      <FormInput id="city" type="text" placeholder="Cidade" value={currentItem.city}
                        onChange={this.handleChange}  />
                    </Col>
                  
                    <Col md="2" className="form-group">
                      <label htmlFor="state">Estado</label>
                      <FormInput id="state" type="text" placeholder="Estado" value={currentItem.state}
                        onChange={this.handleChange}  />
                    </Col>
                  </Row>

                </Form>
              </ModalBody>

              <ModalFooter>
                <Row>
                  <Col>
                    <Button type="button" onClick={this.addAddressHandler}>Adicionar</Button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>

            <SweetAlert
              show={this.state.showAlert}
              warning
              showCancel
              confirmBtnText="Sim, quero remover!"
              confirmBtnBsStyle="danger"
              title="Tem certeza?"
              onConfirm={this.removeAddress}
              onCancel={this.cancelAlert}
              focusCancelBtn
              btnSize="sm">
              Você não será capaz de recuperar o item removido.
            </SweetAlert>
            
            <SweetAlert
            show={this.state.showAlertSuccess}
              success
              title="Wow!"
              onConfirm={this.goToOrders}
              >
              Pedido realizado com sucesso!
            </SweetAlert>

          </div>
        );
    }
}

export default PaymentDelivery;
