import React from 'react';
//import { Switch, Route, Redirect } from "react-router-dom";
import {  Redirect } from "react-router-dom";
import withAuth from './_helpers/withAuth.js';
import withAuthAdmin from './_helpers/withAuthAdmin.js';

// Layout Types
import { DefaultLayout, ExternalLayout, AdminDefaultLayout, AdminExternalLayout } from "./layouts";

// site
import Home from './pages/home/Home';
import Info from './pages/info/Info';
import Cart from './pages/cart/Cart';
import ErrorPage from './pages/errors/Errors';
import Login from './pages/login/Login';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import ResetPassword from './pages/resetpassword/ResetPassword';
import Logout from './pages/logout/Logout';
import Register from './pages/register/Register';
import PaymentDelivery from './pages/payment_delivery/PaymentDelivery';
import UserOrder from './pages/order/Order.js';
import UserOrders from './pages/orders/Orders.js';

// admin
import AdminLogin from './pages_admin/login/Login';

import Dashboard from './pages_admin/dashboard/Dashboard';
import Clients from './pages_admin/clients/Clients';
import Configuration from './pages_admin/configuration/Configuration';
import Products from './pages_admin/products/Products';
import Product from './pages_admin/products/Product';
import Category from './pages_admin/category/Category';
import Order from './pages_admin/orders/Order';
import Orders from './pages_admin/orders/Orders';
import OrdersDay from './pages_admin/orders/OrdersDay';
import AdminLogout from './pages_admin/logout/Logout';

// nao eh formato de classe, posso fazer formato de funcao mesmo
/*
function Routes(){
    return (
        <Switch>
			<Route path="/" exact component={withAuth(Stock)} layout={DefaultLayout} />
			<Route path="/login" exact component={Login} layout={DefaultLayout} />
        </Switch>
    );
}

export default Routes;
*/

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/admin/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/admin/dashboard" />
  },
  /*
  {
    path: "/",
	exact: true,
    layout: ExternalLayout,
	componente: ErrorPage
    //component: withAuth(Stock)
  },
  */

	// site
  { path: "/home", layout: ExternalLayout, component: Home },
  { path: "/info", layout: ExternalLayout, component: Info },
  { path: "/cart", layout: ExternalLayout, component: Cart},
  { path: "/payment_delivery", layout: ExternalLayout, component: withAuth(PaymentDelivery)},
  { path: "/order/:orderid", layout: ExternalLayout, component: withAuth(UserOrder)},
  { path: "/orders", layout: ExternalLayout, component: withAuth(UserOrders)},
	{ path: "/errors", layout: DefaultLayout, component: ErrorPage },
  { path: "/login", layout: ExternalLayout, component: Login },
	{ path: "/logout", layout: ExternalLayout, component: Logout},
	{ path: "/forgotpassword", layout: ExternalLayout, component: ForgotPassword},
	{ path: "/resetpassword/:code", layout: ExternalLayout, component: ResetPassword},
  { path: "/register", layout: ExternalLayout, component: Register},
 
  
  // site - pedidos

  // ----------------------------------------------------------------------------------------------------
	// admin
  { path: "/admin/login", layout: AdminExternalLayout, component: AdminLogin },
  { path: "/admin/logout", layout: AdminExternalLayout, component: withAuthAdmin(AdminLogout) },

  // dashboard
	{ path: "/admin/dashboard", layout: AdminDefaultLayout, component: withAuthAdmin(Dashboard) },
  

  // pedidos
	{ path: "/admin/orders", layout: AdminDefaultLayout, component: withAuthAdmin(Orders) },
	{ path: "/admin/ordersday", layout: AdminDefaultLayout, component: withAuthAdmin(OrdersDay) },
	{ path: "/admin/order/:orderid", layout: AdminDefaultLayout, component: withAuthAdmin(Order) },

  // produtos
  { path: "/admin/products", layout: AdminDefaultLayout, component: withAuthAdmin(Products) },
  { path: "/admin/product/:id?", layout: AdminDefaultLayout, component: withAuthAdmin(Product) },
  
  // categorias
  { path: "/admin/category", layout: AdminDefaultLayout, component: withAuthAdmin(Category) },
  
  // clientes
  { path: "/admin/clients", layout: AdminDefaultLayout, component: withAuthAdmin(Clients) },
  
	{ path: "/admin/configuration", layout: AdminDefaultLayout, component: withAuthAdmin(Configuration) },

];
