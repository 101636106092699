import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Badge, Button  } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';
import Moment from 'react-moment';

import adminApi from '../../services/admin.api';

import './Orders.css';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Order extends Component {

	constructor(props) {
		super(props);

		this.state = {
			items: [],
			edit: false,
			showAlert: false,
			idx: null,
		};
	}

    async componentDidMount(){
		await this.loadData();
	}

	async loadData(){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
	        const response = await adminApi.get('admin/order/'+storeid);
			this.setState({ items: response.data });
		}
	}

	/*
	saveHandler = async () => {
		let currentItem = this.state.currentItem;
 		await adminApi.put(`admin/order/${currentItem._id}`, currentItem);
		await this.loadData();
	}
	*/


	showStatus = (status) => {

		//let statues = [];

		let newstatus = "";
		// pending | confirmed | canceled | delivery | complete
		switch(status){
			case "pending":
				newstatus = <Badge>Novo</Badge>;
				break;
			case "confirmed":
				newstatus = <Badge theme="warning">Confirmado</Badge>;
				break;
			case "canceled":
				newstatus = <Badge theme="danger">Cancelado</Badge>;
				break;
			case "delivery":
				newstatus = <Badge theme="success">Saiu para entrega</Badge>;
				break;
			case "complete":
				newstatus = <Badge theme="secondary">Finalizado</Badge>;
				break;
		}
		return newstatus;
	} 

	handleChangeStatus = async (idx, id, newstatus) => {

		let items = [...this.state.items];
		let item = {...items[idx]};
		item.status = newstatus;
		items[idx] = item;

		await this.changeOrderStatus(item);

		this.setState({items});		
	}

	changeOrderStatus = async (order) => {
		await adminApi.put(`admin/order/status/${this.state.storeid}/${order._id}/`, { status: order.status} );
	} 

	render(){
		const {
			items,
			currentItem
		} = this.state;

		return (
			 <Container fluid className="main-content-container px-6">

				<Card small className="mb-4 content">

					<CardHeader className="p-0 pb-3">
						<Row>
							<Col sm="10">
								<div className="title">
									<h5 className="m-0">
										<FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '7px'}}/>
										Pedidos</h5>
								</div>
							</Col>
							<Col sm="2">
								<div className="btAdd">
									<a href="/admin/order">
										<button className="btn btn-primary btn-sm" type="button">
											<FontAwesomeIcon icon={faPlus} />
										</button>
									</a>
								</div>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="p-0 pb-3">
						<table className="table mb-0">
							<thead className="bg-light">
								<tr>
									<th scope="col" style={{width: '10%'}} className="border-1">#</th>
									<th scope="col" style={{width: '20%'}} className="border-1">Data/Hora</th>
									<th scope="col" style={{width: '20%'}} className="border-1">Cliente</th>
									<th scope="col" style={{width: '10%'}} className="border-1">Valor</th>	
									{ /* <th scope="col" style={{width: '10%'}} className="border-1">Tipo</th> */ }																	
									<th scope="col" style={{width: '10%'}} className="border-1">Status</th>
									<th scope="col" style={{width: '20%'}} className="border-1">Ações</th>
								</tr>
							</thead>
							<tbody>
								{items.map((item, idx) => (
									<tr key={idx}>
										<td>
										<Link to={`/admin/order/${item._id}`}>
											<Button pill theme="success" size="sm">
												#{ item.number}
											</Button>
										</Link>											
											
										</td>
										<td>
											<Moment format="DD/MM/YYYY HH:mm">
												{item.createdAt}
											</Moment>
										</td>
										<td>{item.user.name} {item.user.lastname}</td>
										<td>{parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>										
										{ /* <td>{item.delivery}</td> */ }
										<td>{ this.showStatus(item.status)}</td>
										<td className="acoes">
											{ item.status === "pending" &&
												<span>
													<button className="btn btn-warning btn-sm" type="button"
														onClick={() => this.handleChangeStatus(idx, item._id, 'confirmed')}>
														Confirmar
													</button>
													&nbsp;
													<button className="btn btn-danger btn-sm" type="button"
													onClick={() => this.handleChangeStatus(idx, item._id, 'canceled')}>
														Cancelar
													</button>													
												</span>
											}


											{ item.status === "confirmed" &&
												<span>
													<button className="btn btn-success btn-sm" type="button"
													onClick={() => this.handleChangeStatus(idx, item._id, 'delivery')}>
														Saiu para entrega
													</button>												
												</span>
											}

											{ item.status === "delivery" &&
												<span>
													<button className="btn btn-secondary btn-sm" type="button"
													onClick={() => this.handleChangeStatus(idx, item._id, 'complete')}>
														Finalizar
													</button>												
												</span>
											}
										</td>
									</tr>
							))}
							</tbody>
						</table>
					</CardBody>
				</Card>

			</Container>

		);
	}
}

export default Order;
