import React from "react";
//import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import { NavItem, NavLink, Badge } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons'


export default class Notifications extends React.Component {
    constructor(props) {
      super(props);
    }


  render() {
    return (
      <NavItem className="border-right notifications">
        <NavLink
          className="nav-link-icon text-center ml-auto"
          href="/admin/orders"
        >
          <div className="ml-autonav-link-icon__wrapper">
          <FontAwesomeIcon icon={faBell} style={{fontSize: "24px", marginTop: "10"}}/>
            <Badge pill theme="danger" style={{marginTop:"-10px", marginLeft: "5px"}}>
              2
            </Badge>
          </div>
        </NavLink>
      </NavItem>
    );
  }
}
