import React from 'react';


const Logotipo = (props) => {

	return (
		<img className="auth-form__logo d-table mx-auto mb-3" src="/static/media/shards-dashboards-logo.60a85991.svg" alt="Market Stocks" />
	)

}

export default Logotipo;
