import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, FormCheckbox, ModalFooter } from "shards-react";
import CurrencyFormat from 'react-currency-format';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import SweetAlert from 'react-bootstrap-sweetalert';

//import io from 'socket.io-client';
import adminApi from '../../services/admin.api';

// import CategoryAddModal from '../../components/categoryaddmodal/CategoryAddModal';
import './Category.css';

import {
	Form,
	FormInput,
	Button,
	Modal, ModalBody, ModalHeader
  } from "shards-react";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Category extends Component {

	emptyItem = {
		name: '',
		active: true
	}

	constructor(props) {
		super(props);

		this.state = {
			items: [],
			modalOpen: false,
			currentItem: this.emptyItem,
			edit: false,
			showAlert: false,
			removeItemId: null,
			idx: null,
		};

	}

    async componentDidMount(){
		await this.loadData();
	}

	async loadData(){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
	        const response = await adminApi.get('admin/category/'+storeid);
			this.setState({ items: response.data });
		}
	}

	// add
	handleAdd = async () => {
        await this.setState({
            modalOpen: true,
			edit: false,
        });
	}
	addCategoryHandler = async () =>{
		let currentItem = this.state.currentItem;
		currentItem.storeid = this.state.storeid;
 		await adminApi.post('admin/category/', currentItem);
		this.closeModalHandler();
		await this.loadData();
	}

	// edit
	handleEdit = async (idx, item) => {
		await this.setState({
			modalOpen: true,
			currentItem: item,
			edit: true,
		});
    }
	saveHandler = async () => {
		let currentItem = this.state.currentItem;
 		await adminApi.put(`admin/category/${currentItem._id}`, currentItem);
		this.closeModalHandler();
		await this.loadData();
	}

    handleRemove = (idx, id) => {
		this.setState( { removeItemId: id, idx: idx });
		this.toggleAlert();
	}

	remove = () => {
		this.toggleAlert();
		adminApi.delete(`admin/category/${this.state.removeItemId}/`);
		let array = this.state.items;
		array.splice(this.state.idx, 1);
		this.setState({items: array});
	}

	handleChange = e => {
		e.persist();
		if(e.target.id == 'active'){
			this.setState(prevState => ({
				currentItem: { ...prevState.currentItem,  [e.target.id]: !e.target.value }
			}));
		} else {
			this.setState(prevState => ({
				currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
			}));
		}
	}


	// modal
    closeModalHandler = async () => {
        await this.setState({
            modalOpen: false,
			currentItem: this.emptyItem
        });
	}
		
	// alert
	toggleAlert = () =>{
		this.setState( {showAlert: !this.state.showAlert }); 
	}
	cancelAlert = () => {
		this.setState( { removeItemId: null, idx: null });
		this.toggleAlert();
	}

	render(){
		const {
			items,
			currentItem
		} = this.state;

		return (
			 <Container fluid className="main-content-container px-6">

				 { /*
				<CategoryAddModal
	                className="modal"
	                show={this.state.isShowing}
	                close={this.closeModalHandler}
					add={this.addCategoryHandler}
					save={this.saveHandler}
					handleChange={this.handleChange}
					vars={this.state}
					aria-labelledby="contained-modal-title-vcenter">
				</CategoryAddModal>
				 */ }

				<Card small className="mb-4 content">

					<CardHeader className="p-0 pb-3">
						<Row>
							<Col sm="10">
								<div className="title">
									<h5 className="m-0">
										<FontAwesomeIcon icon={faLayerGroup} style={{marginRight: '7px'}}/>
										Categorias</h5>
								</div>
							</Col>
							<Col sm="2">
								<div className="btAdd">
									<button className="btn btn-primary btn-sm" type="button" onClick={() => this.handleAdd()}>
										<FontAwesomeIcon icon={faPlus} />
									</button>
								</div>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="p-0 pb-3">
						<table className="table mb-0">
							<thead className="bg-light">
								<tr>
									<th scope="col" style={{width: '60%'}} className="border-1">Nome</th>
									<th scope="col" style={{width: '20%'}} className="border-1">Ativo</th>
									<th scope="col" style={{width: '20%'}} className="border-1 acoes">Ações</th>
								</tr>
							</thead>
							<tbody>
								{items.map((cat, idx) => (
									<tr key={idx}>
										<td>{cat.name}</td>
										<td>{cat.active ? "Ativo" : "Desativado"}</td>
										<td className="acoes">
											<Link onClick={() => this.handleEdit(idx, cat)}>
												<FontAwesomeIcon icon={faEdit} className="acoes edit"/>
											</Link>
											<Link onClick={() => this.handleRemove(idx, cat._id)}>
												<FontAwesomeIcon icon={faTrash} className="acoes remove" />
											</Link>
										</td>
									</tr>
							))}
							</tbody>
						</table>
					</CardBody>
				</Card>

				<Modal open={this.state.modalOpen} toggle={this.closeModalHandler}>
					<ModalHeader >
						<Row md="12">
						<Col md='10'>
							<span className="modal-title">
								{ this.state.edit &&  <span>Editar Categoria</span> }
								{ !this.state.edit &&  <span>Adicionar Categoria</span> }
							</span>
						</Col>
						<Col md='2'>
							<span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
						</Col>
						</Row>
					</ModalHeader>
					
					<ModalBody>
						<Form id='categoryForm'>
							<Row form>
								<Col md="12" className="form-group">
									<label htmlFor="name">Nome da categoria</label>
									<FormInput id="name" type="text" placeholder="Nome" value={currentItem.name}
										onChange={this.handleChange}  />
								</Col>
							</Row>
							<Row form>
								<Col md="12" className="form-group">
									<FormCheckbox
										toggle small
										checked={currentItem.active}
										id={"active"}
										onChange={this.handleChange} >
										Ativo
									</FormCheckbox>
								</Col>
							</Row>
						</Form>
					</ModalBody>

					<ModalFooter>
						<Row>
							<Col>
							{ this.state.edit &&  <Button type="button" onClick={this.saveHandler}>Salvar</Button> }
							{ !this.state.edit &&  <Button type="button" onClick={this.addCategoryHandler}>Adicionar</Button> }
							</Col>
						</Row>
					</ModalFooter>
				</Modal>

				<SweetAlert
					show={this.state.showAlert}
					warning
					showCancel
					confirmBtnText="Sim, quero remover!"
					confirmBtnBsStyle="danger"
					title="Tem certeza?"
					onConfirm={this.remove}
					onCancel={this.cancelAlert}
					focusCancelBtn
					btnSize="sm">
					Você não será capaz de recuperar o item removido.
				</SweetAlert>

			</Container>

		);
	}

}

export default Category;
