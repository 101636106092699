
import React from 'react';
import config from '../../config/site.config.js';
import Logotipo from '../../components/logotipo/Logotipo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SubHeaderHome from '../../components/subheaderhome/SubHeaderHome';
import ModalProduct from '../../components/modalproduct/ModalProduct';
import { storeService } from '../../services/store.service';
import { Row, Col, ModalFooter } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash  } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import apiopen from '../../services/apiopen';
import "./Home.css";

import {
	Form,
	FormInput,
	FormTextarea,
	Button,
	Modal, ModalBody, ModalHeader
  } from "shards-react";

class Home extends React.Component {

	emptyItem = {
		_id: '',
		name: '',
		price: '',
		qtd: '1',
		subtotal: '',
		comments: '',
  }
    
  constructor(props) {
    super(props);
    this.state = {
      store: { },
      loading: false,
      error: false,
      categories: [],
      products: [],
      modalOpen: false,
        currentItem: this.emptyItem,
        cartModalOpen: false,
        cart: {
          products: [],
          comments: ''
        },		
    };
  }

  async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
		await this.loadCart(this.state.subdomain);
	}

	async loadCart(domain){
		const cart = localStorage.getItem(`${domain}_cart`);
		if(cart){
			await this.setState({
				cart: JSON.parse(cart)
			});
		}
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					if(store){
						this.setState({ store: store });
						this.loadCategories();
						//this.loadProducts();
					} else {
						console.log("loja nao existe");
					}
					//const { from } = { from: { pathname: "/dashboard" } };
					//this.props.history.push(from);
				},
				error => console.log(error)
				//error => this.setState({ error, loading: false })
			);
		this.setState({ loading: false });
	}

	async loadCategories(){
		const response = await apiopen.get('category/'+this.state.store._id);

		const categories = [];
		response.data.map((cat, idx) => {
			cat.products = [];
			categories.push(cat);
		});

		//console.log(categories);
		this.setState({ categories: categories });
		this.setState({ loading: false });

		this.loadProducts();
	}
	async loadProducts(){
        const response = await apiopen.get('product/'+this.state.store._id);
		this.setState({ products: response.data });
		this.setState({ loading: false });

		const products = response.data;
		const categories = this.state.categories;

		response.data.map((prod, idx) => {
			let item = categories.find((element) => {
				return element._id === prod.category;
			})

			item.products.push(prod);
		});
		this.setState({ categories});
	}

  showCategory = (cat, idx) => {
		if(cat.products.length) {
			return <div className="category">
          <div className="category-title">{cat.name}</div>

          <div className="products row">
            {cat.products.map((prod, idx) => (
              <div className="productOut col-sm-12 col-md-6">
                <Link onClick={() => this.openModal(idx, prod)}>
                  <div className="product">
                    <div className={ prod.image ? 'col-sm-9' : 'col-sm-12' }>
                      <div className="product-title">{prod.name}</div>
                      <div className="product-description">{prod.description}</div>
                      <div className="product-price">{parseFloat(prod.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                    </div>
                    { prod.image &&
                      <div className="productImageOut col-sm-3">
                        <img className="productImage"
                          src={`${config.urlFiles}/${this.state.store._id}/${prod.image}`}/>  
                      </div>
                    }                  
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
		}
  }

	// ----------------------------------------------------------------------
	// adicionar produto no carrinho - inicio
	closeModalHandler = () => {
		this.setState({
			modalOpen: false,
			currentItem: this.emptyItem
		});
	}
	openModal = (idx, item) => {
		item.qtd = 1;
		item.subtotal = item.price;
		this.setState({
			modalOpen: true,
			currentItem: item
		});
	}

	handleChange = e => {
    e.persist();
		if(e.target.id == 'qtd'){
			this.setState(prevState => ({
				currentItem: { ...prevState.currentItem,  ['subtotal']: e.target.value * this.state.currentItem.price }
			}));
    }
		this.setState(prevState => ({
			currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
		}));
  }

  handleLess = e => {
    e.persist();
    let qtd = this.state.currentItem.qtd-1;
    if(qtd < 1) {
      return false;
    }
		this.setState(prevState => ({
      currentItem: { ...prevState.currentItem,
                  ['qtd']: qtd, 
                  ['subtotal']: qtd * this.state.currentItem.price  }
    }));
  }
  
  handleMore = e => {
    e.persist();
    let qtd = this.state.currentItem.qtd+1;
		this.setState(prevState => ({
      currentItem: { ...prevState.currentItem,
                  ['qtd']: qtd, 
                  ['subtotal']: qtd * this.state.currentItem.price  }
    }));
  }
  
	addToCart = (currentItem) => {
    const cart = this.state.cart;

    const item = JSON.parse(JSON.stringify(currentItem));
    cart.products.push(item);
		
		this.setState({
			modalOpen: false,
        currentItem: this.emptyItem,
        cart
		});

		localStorage.setItem(`${this.state.store.name}_cart`, JSON.stringify(this.state.cart));
	}

	// adicionar produto no carrinho - fim
    // -----------------------------------------------------------------------	
    // carrinho de compras - inicio

	closeCartModal = () => {
		this.setState({
			cartModalOpen: false,
		});
	}
	openCartModal = () => {
		this.setState({
			cartModalOpen: true
		});
	}
  showCart = () => {
      console.log(this.state.cart);
  }


    // carrinho de compras - fim
	// -----------------------------------------------------------------------	

    render() {
      const { store, categories, currentItem } = this.state;
      return (
		    <div id="home">
          <Header store={this.state.store} logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`} />
          <SubHeaderHome store={this.state.store} />

          <img src={`${config.urlFiles}/${store._id}/${store.cover_image}`} className="cover" />

          <div className="h-100 no-gutters row">
            <div className="auth-form mx-auto my-auto col-sm-12">
              <div className="card">
                { categories.map((cat, idx) => {
                  return this.showCategory(cat, idx);
                })}
              </div>
            </div>
          </div>
          { /*
          <Footer />
          */
          }

          <ModalProduct
            modalOpen={this.state.modalOpen}
            closeModalHandler={this.closeModalHandler}
            handleChange={this.handleChange}
            addToCartHandler={this.addToCart}
            currentItem={currentItem}
            handleLess={this.handleLess}
            handleMore={this.handleMore}
          />

          { /*
          <Modal open={this.state.modalOpen} toggle={this.closeModalHandler}>
            <ModalHeader >
              <Row md="12">
                <Col md='10'>
                  <span className="modal-title">
                    <span>Adicionar ao carrinho</span>
                  </span>
                </Col>
                <Col md='2'>
                  <span className="close-modal-btn" onClick={this.closeModalHandler}>×</span>
                </Col>
              </Row>
            </ModalHeader>
            
            <ModalBody>
              <Form id='addToCartModal'>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="name">{currentItem.name}</label>
                    <FormInput id="qtd" type="number" value={currentItem.qtd}
                      onChange={this.handleChange}  />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormTextarea id="comments" type="text" placeholder="Exemplo: retirar cebola / sem queijo / sem pimenta"
                      value={currentItem.comments}
                      onChange={this.handleChange}  />
                  </Col>
                </Row>
                <Row>
                  <Col>Valor: {parseFloat(currentItem.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Col>
                  <Col>Subtotal: {parseFloat(currentItem.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Col>
                </Row>
              </Form>
            </ModalBody>

            <ModalFooter>
              <Row>
                <Col>
                  <Button type="button" onClick={() => this.addToCart(currentItem)}>Adicionar</Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          */ }

        </div>
      );
    }
}

export default Home;
