
import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SubHeader from '../../components/subheader/SubHeader';
import { storeService } from '../../services/store.service';
import { Row, Col, ModalFooter } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash  } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import auth from '../../_helpers/auth';
import config from '../../config/site.config.js';

import apiopen from '../../services/apiopen';
import "./Cart.css";
import {
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardImg,
	CardBody,
	CardFooter, 	
} from "shards-react";


class Cart extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        store: { },
        loading: false,
        error: false,
          cart: {
            products: [],
            comments: '',
            subtotal: '',
            delivery_type: '',
            delivery_price: '',
            total: ''
          },		
        };
	}

  async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
		await this.loadCart(this.state.subdomain);
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					this.setState({ store: store });
					this.calcPrices();
				},
				error => console.log(error)
				//error => this.setState({ error, loading: false })
			);
		this.setState({ loading: false });
  }
  
	async loadCart(domain){
		const cart = localStorage.getItem(`${domain}_cart`);
		if(cart){
			await this.setState({
				cart: JSON.parse(cart)
			});
			this.calcPrices();
		}
	}

	// adicionar produto no carrinho - fim
  // -----------------------------------------------------------------------	
  // carrinho de compras - inicio

  removeItemFromCart = async (idx, prod) => {
		let array = this.state.cart.products;
		array.splice(idx, 1);
        //await this.setState({cart: array});
        await this.setState(prevState => ({
			cart: { ...prevState.cart,  ['products']: array }
		}));
		localStorage.setItem(`${this.state.store.name}_cart`, JSON.stringify(this.state.cart));

		this.calcPrices();
		/*
		this.setState(prevState => ({
			cart: { ...prevState.cart,  ['products']: array }
		}, () => {
			localStorage.setItem(`${this.state.store.name}_cart`, JSON.stringify(this.state.cart));
		}));
		*/
	}
	
	calcPrices = async () => {
		const subtotal = this.state.cart.products.reduce((total, item) => total + item.subtotal, 0);
		let total = subtotal;

		if(this.state.store.delivery_type == 'pay'){
			total += this.state.store.delivery_price;
		}

        await this.setState(prevState => ({
			cart: { ...prevState.cart,  ['subtotal']: subtotal, ['total']: total }
		}));		
	}

    // carrinho de compras - fim
	// -----------------------------------------------------------------------	

    render() {
        const { store } = this.state;
        return (
          <div id="cart">
              <Header store={this.state.store} 
                      logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`}
              />
              <SubHeader title="Carrinho" />

              <div className="h-100 no-gutters row">
                <div className="auth-form mx-auto my-auto col-sm-12">
                  <div className="card">

                    { this.state.cart.products.length < 1 &&
                      <div className="empty-cart">Seu carrinho está vazio</div>
                    }

                    { this.state.cart.products.length > 0 &&

                        <Card>
                          <CardBody className="items">
                            <CardTitle className="card-title">Itens do Pedido</CardTitle>
                            <div className="items-list">
                              {this.state.cart.products.map((item, idx) => (
                                <div className="order-item">
                                  { /* <div className="order-qtd">{item.qtd}x</div> */ }
                                  <div className="order-product">
                                    <div className="product-title">{item.qtd}x {item.name}
                                    { /*
                                    - {parseFloat(item.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                    */ }
                                    </div>
                                    
                                    { /*item.description && 
                                      <div className="product-description">{item.description}</div>
                                    */ }
                                    { item.comments && 
                                      <div className="product-comments"><i>Obs.: {item.comments}</i></div>
                                    }
                                  </div>
                                  <div className="order-price">
                                    {parseFloat(item.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                  </div>
                                  <div className="order-remove" onClick={() => this.removeItemFromCart(idx, item)}>
                                    x
                                  </div>
                                </div>
                              ))}
                              </div>

                          <div className="total">
                            <div className="order-delivery">
                              <div className="delivery-description">Subtotal:</div>
                              <div className="delivery-price">
                                {parseFloat(this.state.cart.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              </div>
                            </div>
                            <div className="order-delivery">
                              <div className="delivery-description">Taxa de entrega:</div>
                              <div className="delivery-price">
                                {parseFloat(this.state.store.delivery_price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              </div>
                            </div>
                            <div className="order-total">
                              <div className="total-description">Total:</div>
                              <div className="total-price">
                                {parseFloat(this.state.cart.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              </div>
                            </div>
                          </div>

                          <Row className="rowFinish">
                            
                            <Col sm="12" className="btFinalizarOut">

                              { auth.isAuthenticated() &&
                                <Link to="/payment_delivery">
                                  <Button type="button">Finalizar Pedido</Button>
                                </Link>
                              }
                              { !auth.isAuthenticated() &&
                                <Link to="/login">
                                  <Button type="button">Finalizar Pedido</Button>
                                </Link>
                              }
                            </Col>
                          </Row>																						
                        </CardBody>								

                      </Card>										
                    }

                    </div>
                </div>
              </div>
              { /*
              <Footer />
              */ }
          </div>
        );
    }
}

export default Cart;
