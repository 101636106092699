import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import { adminService } from '../../services/admin.service';
import { storeService } from '../../services/store.service';
import api from '../../services/api';
import "./Login.css";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            submitted: false,
            loading: false,
            error: '',
			subdomain: null,
			store: { title: ''}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
	}
	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}
	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					if(store){
						this.setState({ store: store });
					} else {
						console.log("loja nao existe");
					}
				},
				//error => console.log(error)
				error => this.setState({ error, loading: false })
			);
		this.setState({ loading: false });
	}

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        //const { email, password, returnUrl } = this.state;
        const { email, password } = this.state;

        // stop here if form is invalid
        if (!(email && password)) {
            return;
        }

        this.setState({ loading: true });

        adminService.login(email, password, this.state.subdomain)
            .then(
                user => {
                    const { from } = { from: { pathname: "/admin/dashboard" } };
					this.props.history.push(from);
                },
                error => this.setState({ error, loading: false })
            );
			this.setState({ loading: false });
    }

    render() {
        const { store, email, password, submitted, loading, error } = this.state;
        return (
			<div class="main-content-container container-fluid px-4 my-auto h-100">
				<div class="h-100 no-gutters row">
					<div class="auth-form mx-auto my-auto col-md-5 col-lg-3">
						<div class="card">
							<div class="card-body">
								<Logotipo />
								<h5 class="auth-form__title text-center mb-4">Painel de administração</h5>
								<h6 className="text-center">{ store.title }</h6>
								<form name="form" onSubmit={this.handleSubmit}>
									<div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
										<label htmlFor="email">E-mail</label>
										<input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
										{submitted && !email &&
											<div className="help-block">E-mail é obrigatório</div>
										}
									</div>
									<div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
										<label htmlFor="password">Senha</label>
										<input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
										{submitted && !password &&
											<div className="help-block">Senha é obrigatório</div>
										}
									</div>
									{ /*
									<div class="form-group">
										<label class="custom-control custom-checkbox">
											<input id="dr-checkbox-giXl7BqkI" type="checkbox" class="custom-control-input" />
											<label id="dr-checkbox-giXl7BqkI" class="custom-control-label" aria-hidden="true"></label>
											<span class="custom-control-description">Lembrar-me por 30 dias.</span>
										</label>
									</div>
									*/ }
				                    <div className="form-group">
				                        {loading &&
				                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
				                        }
				                    </div>
				                    {error &&
				                        <div className={'alert alert-danger'}>{error}</div>
				                    }
									<button className="d-table mx-auto btn btn-accent btn-pill" disabled={loading}>Acessar sua conta</button>
								</form>

								<div className="forgot-password text-center sm-col-12">
									<a href="/admin/forgotpassword">Esqueci minha senha</a>
								</div>
							</div>
							{ /*
							<div class="card-footer">
								<ul class="auth-form__social-icons d-table mx-auto">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
									<li><a href="#"><i class="fab fa-twitter"></i></a></li>
									<li><a href="#"><i class="fab fa-github"></i></a></li>
									<li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
								</ul>
							</div>
							*/ }
						</div>

					</div>
				</div>
			</div>

        );
    }
}

export default Login;
