import React, { Component } from 'react';

import adminApi from '../../services/admin.api';

import {
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
//  FormFile,
  FormInput,
  FormGroup,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import "./Product.css";


//const Product = (props) => {
class Product extends Component {

    currentItem = {
        name: '',
        category: '',
        description: '',
        price: '',
        image: '',
        active: '',
    }

    constructor(props) {
		super(props);

		this.state = {
            categories: [],
            currentItem: this.currentItem,
            edit: false,
            id: null
        };

	}
    async componentDidMount(){
        const { match: { params } } = this.props;
        await this.loadData();
        if(params.id){
            await this.loadItemData(params.id);
        }
    }

    async loadData(){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
	        const response = await adminApi.get('admin/category/'+storeid);
			this.setState({ categories: response.data });
		}
	}

	async loadItemData(id){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
	        const response = await adminApi.get('admin/product/'+storeid+'/'+id);
            this.setState({ currentItem: response.data, edit: true });

            console.log(this.state.currentItem);
        }
    
    }

    addHandler = async () =>{
		let currentItem = this.state.currentItem;
		currentItem.storeid = this.state.storeid;
 		await adminApi.post('admin/product/', currentItem);
    }
    saveHandler = async () =>{
        let currentItem = this.state.currentItem;
        /*
        // without image
		currentItem.storeid = this.state.storeid;
        await adminApi.put(`admin/product/${currentItem._id}`, currentItem);
        */

        // with images
        const data = new FormData();
        data.append('storeid', this.state.storeid);
        data.append('name', currentItem.name);
        data.append('category', currentItem.category);
        data.append('description', currentItem.description);
        data.append('price', currentItem.price);
 
        data.append('image', this.state.image);
        data.append('active', currentItem.active);

        await adminApi.put(`admin/product/${currentItem._id}`, data);
    }

    handleChange = e => {
		e.persist();

        this.setState(prevState => ({
            currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
        }));
	}
    handleImageChange = e => {
        this.setState({
            image_preview: URL.createObjectURL(e.target.files[0]),
            image: e.target.files[0]
        });
    }

    render(){
		const {
            categories,
            currentItem,
            edit,
        } = this.state;
        
        return (
            <Container fluid className="main-content-container px-6">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                { !edit && <PageTitle sm="4" title="Adicionar Produto" subtitle="" className="text-sm-left" /> }
                { edit && <PageTitle sm="4" title="Alterar Produto" subtitle="" className="text-sm-left" /> }
            </Row>

            <Row>
            <Col>

                <ListGroup flush>
                    <ListGroupItem className="p-3">
                        <Row>
                        <Col>
                            <Form id='form'>
                            <FormGroup>
                                <label htmlFor="feInputAddress">Nome</label>
                                
                                <FormInput id="name" type="text" placeholder="Nome" value={currentItem.name}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="feInputAddress">Categoria</label>

                                <FormSelect id="category"
                                    value={currentItem.category}
                                    onChange={this.handleChange}>
                                    {categories.map((cat, idx) => (
                                        <option value={cat._id}>{cat.name}</option>
							        ))}
                                </FormSelect>
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="feInputAddress">Descrição</label>
                                <FormTextarea id="description" type="text" placeholder="Descrição"
                                    value={currentItem.description}
                                    onChange={this.handleChange} />
                            </FormGroup>

                            <Row form>
                                <Col md="6" className="form-group">
                                <label htmlFor="feInputState">Preço</label>
                                <FormInput id="price" type="number" placeholder="Preço"
                                    onChange={this.handleChange}  value={currentItem.price}/>
                                </Col>
                                <Col md="6" className="form-group">
                                <label htmlFor="active">Em Estoque</label>
                                <FormSelect id="active"
                                    value={currentItem.active}
                                    onChange={this.handleChange}>
                                    <option value="1">Em estoque</option>
                                    <option value="0">Fora de estoque</option>
                                </FormSelect>
                                </Col>
                            </Row>

                            <Row form>
                                <Col md="6" className="form-group">
                                    <FormGroup>
                                        <label htmlFor="feInputAddress">Foto</label>
                                        <FormInput id="image" type="file"
                                            onChange={this.handleImageChange}  />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="form-group">
                                    { this.state.image_preview && <img class="imagePreview" alt="" src={this.state.image_preview}/> }
                                    { !this.state.image_preview && currentItem.image &&
                                        <img class="imagePreview" alt="" src={`http://localhost:3333/files/${this.state.storeid}/${currentItem.image}`}/> }                            
                                </Col>
                            </Row>

                            { !edit && <Button type="button" className="btAction"  onClick={this.addHandler}>Adicionar Produto</Button> }
                            { edit && <Button type="button" className="btAction" onClick={this.saveHandler}>Salvar Produto</Button> }
                            </Form>
                        </Col>
                        </Row>
                    </ListGroupItem>
                    </ListGroup>

                </Col>
            </Row>
            </Container>
        );
    }

    

}

export default Product;
