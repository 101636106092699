import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import adminApi from '../../services/admin.api';


import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,  
  Row,
  Col,
  Button,
  Badge,
  ListGroup, ListGroupItem  
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import "./Order.css";

Moment.globalLocale = 'pt-br';
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Order extends Component {

    order = {
        number: '',
        payment_method: '',
        user: {
            name: '',
            lastname: '',
            email: '',
            phone: '',
        },
        address: {
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
        },
        products_full: [],
        status_log: []
    }

    static defaultProps = {
        center: {
          lat: 59.95,
          lng: 30.33
        },
        zoom: 11
    };

    constructor(props) {
		super(props);

		this.state = {
            order: this.order,
            orderid: null,

            paymentMethods: [
                { code: 'din', title: 'Dinheiro'},
                { code: 'cdm', title: 'Cartão de débito master'},
                { code: 'cdv', title: 'Cartão de débito visa'},
                { code: 'ccm', title: 'Cartão de crédito master'},
                { code: 'ccv', title: 'Cartão de crédito visa'},
            ]                
        };

	}
    async componentDidMount(){
        const { match: { params } } = this.props;
        if(params.orderid){
            await this.loadItemData(params.orderid);
        } else {
            console.log("nao foi informado o id da ordem");
        }
    }

	async loadItemData(id){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
            await this.setState({ storeid });
            this.loadOrder(storeid, id)
        } else {
            console.log("nao carregou o storeid");
        }
    
    }
    async loadOrder(storeid, id){
        const response = await adminApi.get(`admin/order/${storeid}/${id}`);
        this.setState({ order: response.data, edit: true });
        console.log(response.data);        
    }

    showPaymentMethod = (payment_method) => {
        let item = this.state.paymentMethods.find((element) => {
            return element.code === payment_method;
        })
        if(item){
            return(item.title);
        } else {
            return("--");
        }
    }

    showStatus = (status) => {

		//let statues = [];

		let newstatus = "";
		// pending | confirmed | canceled | delivery | complete
		switch(status){
			case "pending":
				newstatus = <Badge>Novo</Badge>;
				break;
			case "confirmed":
				newstatus = <Badge theme="warning">Confirmado</Badge>;
				break;
			case "canceled":
				newstatus = <Badge theme="danger">Cancelado</Badge>;
				break;
			case "delivery":
				newstatus = <Badge theme="success">Saiu para entrega</Badge>;
				break;
			case "complete":
				newstatus = <Badge theme="secondary">Finalizado</Badge>;
				break;
		}
		return newstatus;
	} 

	handleChangeStatus = async (id, newstatus) => {

		let order = this.state.order;
		order.status = newstatus;
		await this.changeOrderStatus(order);

        this.setState({order});
        
        await this.loadOrder(this.state.storeid, order._id);
	}

	changeOrderStatus = async (order) => {
		await adminApi.put(`admin/order/status/${this.state.storeid}/${order._id}/`, { status: order.status} );
	} 

    render(){
		const {
            order
        } = this.state;
        
        return (
            <Container fluid className="content main-content-container px-6">
                <Row>
                    <Col sm="12" md="8">
                        <Card>
                            <CardHeader className="card-header">#{order.number} - <Moment format="DD/MM/YYYY HH:mm">{order.createdAt}</Moment>
                            </CardHeader>
                            <CardBody className="card-body">
                                <CardTitle>Cliente</CardTitle>
                                <p>{order.user.name} {order.user.lastname} <br />
                                E-mail: { order.user.email } <br />                                
                                Telefone: { order.user.phone } <br />
                                </p>
                            </CardBody>
                            <CardBody className="card-body">
                                <CardTitle>Pedido</CardTitle>

                                <table className="table mb-0">
							        <thead className="bg-light">
                                        <tr>
                                            <th scope="col" style={{width: '20%'}} className="border-1">Qtd</th>
                                            <th scope="col" style={{width: '40%'}} className="border-1">Produto</th>
                                            <th scope="col" style={{width: '20%'}} className="border-1">V. Unitário</th>
                                            <th scope="col" style={{width: '20%'}} className="border-1">Valor</th>
                                        </tr>
                                    </thead>
							        <tfoot className="bg-light">
                                        <tr>
                                            <th scope="col" colspan="3" className="border-1 subtotal">Subtotal</th>
                                            <th scope="col" className="border-1">
                                                {parseFloat(order.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" colspan="3" className="border-1 delivery">Taxa de entrega</th>
                                            <th scope="col" className="border-1">
                                                {parseFloat(order.delivery_price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" colspan="3" className="border-1 total">Total</th>
                                            <th scope="col" colspan="3" className="border-1">
                                                {parseFloat(order.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                            </th>
                                        </tr>
                                    </tfoot>                                    
                                    <tbody>
                                        {order.products_full.map((item, idx) => (
                                            <tr>
                                                <td className="qtd">{item.quantity}</td>
                                                <td className="name">{item.name}
                                                { item.comments && 
                                                    <span className="comments"><br/><i>Obs: {item.comments}</i></span>
                                                }
                                                </td>
                                                <td className="price">
                                                    {parseFloat(item.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                                </td>
                                                <td className="amount">
                                                    {parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                            <CardBody className="card-body">
                                <CardTitle>Forma de Pagamento</CardTitle>
                                { this.showPaymentMethod(order.payment_method)}
                            </CardBody>                            
                            <CardFooter className="buttons">
                            { order.status === "pending" &&
                                <span>
                                    <button className="btn btn-warning btn-sm" type="button"
                                        onClick={() => this.handleChangeStatus(order._id, 'confirmed')}>
                                        Confirmar
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-danger btn-sm" type="button"
                                    onClick={() => this.handleChangeStatus(order._id, 'canceled')}>
                                        Cancelar
                                    </button>													
                                </span>
                            }


                            { order.status === "confirmed" &&
                                <span>
                                    <button className="btn btn-success btn-sm" type="button"
                                    onClick={() => this.handleChangeStatus(order._id, 'delivery')}>
                                        Saiu para entrega
                                    </button>												
                                </span>
                            }

                            { order.status === "delivery" &&
                                <span>
                                    <button className="btn btn-secondary btn-sm" type="button"
                                    onClick={() => this.handleChangeStatus(order._id, 'complete')}>
                                        Finalizar
                                    </button>												
                                </span>
                            }
                            
                            { order.status === "complete" &&
                                <span className=" btn-secondary btn-sm">
                                        Pedido finalizado											
                                </span>
                            }
                            { order.status === "canceled" &&
                                <span className=" btn-danger btn-sm">
                                        Pedido cancelado											
                                </span>
                            }                                

                                <Link to={'/admin/ordersday/'}>
                                    <Button pill theme="primary" size="sm">
                                        Voltar para pedidos atuais
                                    </Button>
                                </Link>	                                

                            </CardFooter>
                        </Card>
                        <br /><br />
                    </Col>
                    <Col sm="12" md="4">
                        <Card>
                            <CardHeader className="card-header">Endereço</CardHeader>
                            <CardBody>
                                <p>{order.address.address}, {order.address.number} <br />
                                {order.address.complement} <br />
                                {order.address.neighborhood} / {order.address.city} / {order.address.state} <br />
                                </p>
                            </CardBody>                            
                            <CardBody>
                                <div className="mapa">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "" }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}
                                        >
                                        <AnyReactComponent
                                            lat={59.955413}
                                            lng={30.337844}
                                            text="My Marker"
                                        />
                                        </GoogleMapReact>                                
                                </div>
                            </CardBody>
                        </Card>

                        <br />
                        <Card>
                            <CardHeader className="card-header">Histórico do Status</CardHeader>
                            <CardBody>
                                 <div className="hist_status">
                                    <div><FontAwesomeIcon icon={faBell} className="icon_bell"/></div>
                                    <div className="status">{ this.showStatus("pending") }</div>
                                    <div><FontAwesomeIcon icon={faClock} className="icon_clock"/></div>
                                    <div className="datetime"><Moment format="DD MMM YYYY">{ order.createdAt}</Moment>
                                        <br /><Moment format="HH:mm">{ order.createdAt}</Moment></div>
                                </div>                                
                                {order.status_log.map((item, idx) => (
                                    <div className="hist_status">
                                        <div><FontAwesomeIcon icon={faBell} className="icon_bell"/></div>
                                        <div className="status">{ this.showStatus(item.status) }</div>
                                        <div><FontAwesomeIcon icon={faClock} className="icon_clock"/></div>
                                        <div className="datetime"><Moment format="DD MMM YYYY">{ item.datetime}</Moment>
                                            <br /><Moment format="HH:mm">{ item.datetime}</Moment></div>
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
            </Container>
        );
    }

}

export default Order;
