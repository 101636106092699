import config from '../config/site.config';
export const adminService = {
    login,
    logout,
    getAll
};

// componente no formato de classe
function login(email, password, store) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, store })
    };

    //return fetch(`http://localhost:3333/admin/authenticate`, requestOptions)
    return fetch(`${config.apiUrl}/admin/authenticate`, requestOptions)
        .then(handleResponse)
        .then(admin => {
            // login successful if there's a admin in the response
            if (admin) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                admin.authdata = window.btoa(email + ':' + password);
				//console.log(admin);
                localStorage.setItem('admin', JSON.stringify(admin));
                localStorage.setItem('admin-auth-token', admin.token);
            }

            return admin;
        });
}



function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('admin');
    localStorage.removeItem('admin-auth-token');
}

function getAll(){

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
				console.log("saiu");
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
