import React from 'react';
import { Link } from 'react-router-dom';
import auth from '../../_helpers/auth';

import './Footer.css';

import logo from '../../assets/logo.svg';
import camera from '../../assets/camera.svg';

export default function components(props) {
  
  return (
    <footer id="main-footer">
        <div className="footer-content">
            <div>
              <Link to="/">
                  <img src={logo} alt="{ props.store.title}" />
              </Link>
            </div>

          <div className="buttonInOut">
            <Link to="/cart">
                <img src={camera} alt="Sacola" />Sacola
            </Link>
          </div>
        </div>
    </footer>
  );
}
