
import React from 'react';
import { Redirect } from "react-router-dom";

import { userService } from '../../services/user.service';
import auth from '../../_helpers/auth';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        //userService.logout();
        auth.signOut();

        //const { history } = this.props;
        //history.push('/home');
    }

    render() {
        return (
            <Redirect to="/" />
        );
    }
}

export default Logout;
