import { faTachometerAlt, faShoppingCart, faShoppingBasket,
         faUsers, faSignOutAlt, faCogs, faLayerGroup } from '@fortawesome/free-solid-svg-icons'

export default function() {
	return [
		{
			title: "Painel",
            icon: faTachometerAlt,
            to: "/admin/dashboard",
		},
		{
			title: "Pedidos Atuais",
			icon: faShoppingCart,
			to: "/admin/ordersday",
			htmlAfter: "<div class='blob red'></div>"
		},
		{
			title: "Pedidos",
			icon: faShoppingCart,
			to: "/admin/orders",
		},
		{
			title: "Categorias",
			icon: faLayerGroup,
			to: "/admin/category",
		},		
		{
			title: "Produtos",
			icon: faShoppingBasket,
			to: "/admin/products",
		},
		{
			title: "Clientes",
			icon: faUsers,
			to: "/admin/clients",
		},
		{
			title: "Configurações",
			icon: faCogs,
			to: "/admin/configuration",
		},
		{
			title: "Sair",
			icon: faSignOutAlt,
			to: "/admin/logout",
		},

	  /*
    {
      title: "Blog Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Add New Post",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
	*/

  ];
}
