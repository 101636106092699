
import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SubHeader from '../../components/subheader/SubHeader';
import config from '../../config/site.config.js';
import { storeService } from '../../services/store.service';
import { Row, Col, ModalFooter } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faGripLines } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

import api from '../../services/api';
import "./Order.css";

import {
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardImg,
	CardBody,
	CardFooter, 	
} from "shards-react";

Moment.globalLocale = 'pt-br';

class Order extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      store: { },
      loading: false,
      error: false,
      order: {
        products_full: [],
        comments: '',
        subtotal: '',
        delivery_type: '',
        delivery_price: '',
                amount: '',
                address: {},
                comments: ''
      },
      paymentMethods: [
          { code: 'din', title: 'Dinheiro'},
          { code: 'cdm', title: 'Cartão de débito master'},
          { code: 'cdv', title: 'Cartão de débito visa'},
          { code: 'ccm', title: 'Cartão de crédito master'},
          { code: 'ccv', title: 'Cartão de crédito visa'},
      ]
    };
	}

  async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);

    const { match: { params } } = this.props;
    if(params.orderid){
        await this.loadOrder(this.state.store._id, params.orderid);
    }
	}

	async loadOrder(storeid, orderid){
		const response = await api.get(`order/${storeid}/${orderid}`);
		this.setState({ order: response.data });
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		await storeService.getStore(storename)
			.then(
				store => {
					this.setState({ store: store });
				},
				error => console.log(error)
			);
		this.setState({ loading: false });
	}


    showStatus = (status) => {
		let newstatus = "";
		// pending | confirmed | canceled | delivery | complete
		switch(status){
			case "pending":
				newstatus = <span>Aguardando confirmação</span>;
				break;
			case "confirmed":
				newstatus = <span theme="warning">Confirmado</span>;
				break;
			case "canceled":
				newstatus = <span theme="danger">Cancelado</span>;
				break;
			case "delivery":
				newstatus = <span theme="success">Saiu para entrega</span>;
				break;
			case "complete":
				newstatus = <span theme="secondary">Finalizado</span>;
				break;
		}
		return newstatus;
	} 

    showPaymentMethod = (payment_method) => {
        let item = this.state.paymentMethods.find((element) => {
            return element.code === payment_method;
        })
        if(item){
            return(item.title);
        } else {
            return("--");
        }
    }

    render() {
        const { store, order, load } = this.state;
        return (
			<div id="order" className="content">
        <Header store={this.state.store} 
                logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`}
        />
        <SubHeader title="Pedido" />

				<div className="h-100 no-gutters row">
					<div className="auth-form mx-auto my-auto col-sm-12">
						{ order.status && 
							<div className="card">        
							<Card>
								<CardBody className="card-body">
									<Row className="basic">
										<Col sm="12" md="6">Realizado em <Moment format="DD/MM/YYYY HH:mm">{order.createdAt}</Moment></Col>
										<Col sm="12" md="6">Status: { this.showStatus(order.status)}</Col>
									</Row>
								</CardBody>
							</Card>
              
              { ((order.status == 'pending') || (order.status == 'confirmed') || (order.status == 'delivery')) &&
              <Card>
								<CardBody className="card-body">
                  <div className="list-order-content">
                    <div className="recebido">
                      <div className="status-icon active">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </div>
                      <div className="status-text">Recebido</div>
                    </div>

                    <div className="space"><FontAwesomeIcon icon={faGripLines} /></div>

                    <div className="confirmado">
                      <div className={`status-icon ${((order.status == 'confirmed') || (order.status == 'delivery')) ? "active" : ""}`}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </div>
                      <div className="status-text">Confirmado</div>
                    </div>

                    <div className="space"><FontAwesomeIcon icon={faGripLines} /></div>

                    <div className="enviado">
                    <div className={`status-icon ${(order.status == 'delivery') ? "active" : ""}`}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                      <div className="status-text">Enviado</div>
                    </div>
                  </div>

								</CardBody>
							</Card>
              }
							<Card>
								<CardBody className="card-body">
									<CardTitle>Itens do Pedido</CardTitle>
										{order.products_full.map((item, idx) => (
											<div className="order-item">
												<div className="order-qtd">{item.quantity}x</div>
												<div className="order-product">
													<div className="product-title">{item.name}</div>
													{ item.description && 
														<div className="product-description">{item.description}</div>
													}
													{ item.comments && 
														<div className="product-comments"><i>Obs.: {item.comments}</i></div>
													}
												</div>
												<div className="order-price">
													{parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
												</div>
											</div>
										))}

										<div className="total">
											<div className="order-delivery">
												<div className="delivery-description">Subtotal:</div>
												<div className="delivery-price">
													{parseFloat(order.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
												</div>
											</div>
											<div className="order-delivery">
												<div className="delivery-description">Taxa de entrega:</div>
												<div className="delivery-price">
													{parseFloat(order.delivery_price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
												</div>
											</div>
											<div className="order-total">
												<div className="total-description">Total:</div>
												<div className="total-price">
													{parseFloat(order.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
												</div>
											</div>
										</div>										
								</CardBody>								
							</Card>

							<Card>
								<CardBody className="card-body">
									<CardTitle>Endereço de entrega</CardTitle>
									<Row>
										<Col sm="12">{order.address.address}, {order.address.number} </Col>
										<Col sm="12">{order.address.neighborhood} - {order.address.zipcode} </Col>
										<Col sm="12">{order.address.city} - {order.address.state} </Col>
									</Row>
								</CardBody>
							</Card>

							<Card>
								<CardBody className="card-body">
									<CardTitle>Pagamento</CardTitle>
									<Row>
										<Col sm="12">{this.showPaymentMethod(order.payment_method)}</Col>
									</Row>
								</CardBody>
							</Card>

							{order.comments && 
							<Card>
								<CardBody className="card-body">
									<CardTitle>Observações</CardTitle>
								</CardBody>
								<Row>
									<Col sm="12">{order.comments}</Col>
								</Row>
							</Card>
							}
                           
                        </div>
						}
					</div>
				</div>
				
                <Footer />
            </div>

        );
    }
}

export default Order;
