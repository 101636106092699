
import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import SubHeader from '../../components/subheader/SubHeader';
import { storeService } from '../../services/store.service';
import config from '../../config/site.config.js';

import apiopen from '../../services/apiopen';
import "./Info.css";

class Info extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      store: { },
      loading: false,
      error: false
    };
  }

  async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
		//await this.loadCart(this.state.subdomain);
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					this.setState({ store: store });
				},
				error => console.log(error)
			);
		this.setState({ loading: false });
	}

  render() {
    const { store } = this.state;
    return (
      <div id="info">
          <Header store={this.state.store} 
                  logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`}
          />
          <SubHeader title="Informações" />

          <div className="content">
            Horário de funcionamento
          </div>

          <Footer />
        </div>
      );
  }
}

export default Info;
