import { userService } from '../services/user.service';

class Auth {
	user = null;
	isLogin = false;

	constructor() {
		this.user = JSON.parse(localStorage.getItem('user'));

		this.getProfile = this.getProfile.bind(this);
		//this.handleAuthentication = this.handleAuthentication.bind(this);
		//this.isAuthenticated = this.isAuthenticated.bind(this);
		//this.signIn = this.signIn.bind(this);
		//this.signOut = this.signOut.bind(this);
	}

	getProfile() {
		return this.user;
		//return this.profile;
	}

	getIdToken() {
		//return this.idToken;
	}

	isAuthenticated2() {
		//return (this.isLogin ? true : false);
		console.log("user");
		console.log(this.user);
		return false;
	}

	isAuthenticated() {
		//return (this.isLogin ? true : false);
		return (this.user ? true : false);
	}

	setUser(user){
		this.isLogin = true;
		this.user = user;
	}

	async signIn(email, password, subdomain) {
		this.isLogin = true;
		userService.login(email, password, subdomain)
            .then(
                user => {
                    this.isLogin = true;
                },
                error => { this.isLogin = false; }
			);
		return(this.isLogin);
	}

	signOut() {
		this.isLogin = false;
		this.user = null;
		localStorage.removeItem('user');
		localStorage.removeItem('auth-token');
							
		//const { from } = { from: { pathname: "/home" } };
		//this.props.history.push(from);
	}
}

const auth = new Auth();

export default auth;
