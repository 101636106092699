import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import auth from '../../_helpers/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

import camera from '../../assets/camera.svg';

class Header extends Component {

  constructor(props){
    super(props);
  }

	getCartItemsQtd(){
		const cart = localStorage.getItem(`${this.props.store.name}_cart`);
		if(cart){

      const cartJson = JSON.parse(cart);
      if(cartJson.products.length){
        return cartJson.products.length;
      } else { 
        return '';
      }
		}
	}

  handleBack = () => {
    this.props.history.goBack()
  }

  handleForward = () => {
    console.log(this.props.history)
    this.props.history.go(+1)
  }


  render() {
    return  <header id="main-header">
      <div className="header-content">
          <div class="divLogotipo">
            <Link to="/">
                <img src={this.props.logotipo} className="logotipo" alt="{ this.props.store.title}" />
            </Link>
          </div>

        <div className="buttonInOut">

          {
            !auth.isAuthenticated() &&
            <div className="buttonIn">
              <Link to="/login">
                <button className="btn btn-dark">Entrar</button>
              </Link>
            </div>
          }
          {
            auth.isAuthenticated() &&
              <div className="">
                <label className="">Olá {auth.getProfile().name}</label>
                <Link to="/orders">
                  <button className="btn btn-dark">Pedidos</button>
                </Link>
                <Link to="/profile">
                  <button className="btn btn-dark">Perfil</button>
                </Link>                                    
                <Link to="/logout">
                  <button className="btn btn-dark">Sair</button>
                </Link>
              </div>
          }

          { /*
          <div className="basket">
              <Link to="/cart">
                  <FontAwesomeIcon icon={faShoppingCart} className="cartIcon" />
                  <span className="cartNumber">{ this.getCartItemsQtd() }</span>
              </Link>
          </div>
          */ }
        </div>
      </div>
    </header>
  }
}

export default withRouter(Header)

/*
import React from 'react';
import { Link } from 'react-router-dom';
import auth from '../../_helpers/auth';
import './Header.css';

import camera from '../../assets/camera.svg';

export default function components(props) {
  
  return (
    <header id="main-header">
        <div className="header-content">
            <div>
              <Link to="/">
                  <img src={props.logotipo} className="logotipo" alt="{ props.store.title}" />
              </Link>
            </div>

          <div className="buttonInOut">

            {
              !auth.isAuthenticated() &&
              <div className="buttonIn">
                <Link to="/login">
                  <button className="btn btn-dark">Entrar</button>
                </Link>
              </div>
            }
            {
              auth.isAuthenticated() &&
                 <div className="">
                  <label className="">Olá {auth.getProfile().name}</label>
                  <Link to="/orders">
                    <button className="btn btn-dark">Pedidos</button>
                  </Link>
                  <Link to="/profile">
                    <button className="btn btn-dark">Perfil</button>
                  </Link>                                    
                  <Link to="/logout">
                    <button className="btn btn-dark">Sair</button>
                  </Link>
                </div>
            }

            <Link to="/cart">
                <img src={camera} alt="Sacola" />Sacola
            </Link>

          </div>
        </div>
    </header>
  );
}
*/