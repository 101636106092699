import React from 'react';
import Logotipo from '../../components/logotipo/Logotipo';
import { userService } from '../../services/user.service';
import api from '../../services/api';
import "./ForgotPassword.css";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            submitted: false,
			ok: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email } = this.state;

        // stop here if form is invalid
        if (!(email)) {
            return;
        }

        this.setState({ loading: true });

        await api.post("/user/forgotpassword",{email})
            .then(
                code => {
					console.log(code);
					this.setState({ loading: false, ok: true })
                },
                error => this.setState({ error: "E-mail não encontrado", loading: false })
            );
    }

    render() {
        const { email, password, submitted, loading, error, ok } = this.state;
        return (
			<div className="main-content-container container-fluid px-4 my-auto h-100">

				<div className="h-100 no-gutters row">
					<div className="auth-form mx-auto my-auto col-md-5 col-lg-3">
						<div className="card">
							<div className="card-body">
								<Logotipo />
								<h5 className="auth-form__title text-center mb-4">Esqueci minha senha</h5>
								<form name="form" onSubmit={this.handleSubmit}>

									{!ok &&
									<div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
										<label htmlFor="email">E-mail</label>
										<input type="text" className="form-control" placeholder="Digite seu e-mail" name="email" value={email} onChange={this.handleChange} />
										<small id="emailHelp" className="form-text text-muted text-center">Você receberá um e-mail com um link.</small>
										{submitted && !email &&
											<div className="help-block">E-mail é obrigatório</div>
										}
									</div>
									}

				                    <div className="form-group">

				                        {loading &&
				                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
				                        }
				                    </div>
				                    {error &&
				                        <div className={'alert alert-danger'}>{error}</div>
				                    }

									{submitted && ok &&
										<div className="help-block">Um email com o link para resetar sua senha foi encaminhado para o seu e-mail.</div>
									}
									{!ok &&
			                        <button className="btn btn-pill btn-accent d-table mx-auto" disabled={loading}>Recuperar senha</button>
									}
								</form>
							</div>
						</div>
						<div className="auth-form__meta d-flex mt-4">
							<a className="mx-auto" href="/login">Voltar para o login.</a>
						</div>
					</div>
				</div>
			</div>


        );
    }
}

export default ForgotPassword;
