import React, { Component } from 'react';

import adminApi from '../../services/admin.api';

import {
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormCheckbox,
//  FormFile,
  FormInput,
  FormGroup,
//  FormSelect,
  FormTextarea,
  Button,
  FormRadio
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import "./Configuration.css";

class Configuration extends Component {

    currentItem = {
		//name: '',
		title: '',
		address: '',
        phone: '',
        description: '',
        payment_methods: [],
        delivery_type: '',
        delivery_price: '0',
        days: {
            segunda: { open: 1, from: '', to: ''},
            terca: { open: 1, from: '', to: ''},
            quarta: { open: 1, from: '', to: ''},
            quinta: { open: 1, from: '', to: ''},
            sexta: { open: 1, from: '', to: ''},
            sabado: { open: 1, from: '', to: ''},
            domingo: { open: 1, from: '', to: ''},
        }
    }

    constructor(props) {
		super(props);

		this.state = {
            currentItem: this.currentItem,
            id: null,
            paymentMethods: [
                { code: 'din', title: 'Dinheiro', check: false},
                { code: 'cdm', title: 'Cartão de débito master', check: false},
                { code: 'cdv', title: 'Cartão de débito visa', check: false},
                { code: 'ccm', title: 'Cartão de crédito master', check: false},
                { code: 'ccv', title: 'Cartão de crédito visa', check: false},
            ]
        };
	}

    async componentDidMount() {
    	await this.loadData();
    }

    async loadData(){
		const storeid = await localStorage.getItem('store-token');
		if(storeid){
			await this.setState({ storeid });
            const response = await adminApi.get('admin/store/'+storeid);
            
            //response.data.days = this.currentItem.days;

            this.setState({ currentItem: response.data });
            
            // marca os meios de pagamento
            // organiza os meios de pagamento
            let payment_methods = [];
            let paymentMethods = this.state.paymentMethods;
            this.state.currentItem.payment_methods.map((payment, idx) => {
                let item = paymentMethods.find((element) => {
                    return element.code === payment;
                })
                if(item){
                    item.check = true;
                }
            })   
            this.setState({paymentMethods});

		}
	}

    saveHandler = async () => {
        // organiza os meios de pagamento
        let payment_methods = [];
        let paymentMethods = this.state.paymentMethods;
        paymentMethods.map((item, idx) => {
            if(item.check){
                payment_methods.push(item.code);
            }
        })
    
        // salva os dados da loja
        
        // without image
        let currentItem = this.state.currentItem;        
        /*
        currentItem.storeid = this.state.storeid;
        currentItem.payment_methods = payment_methods;
        await adminApi.put(`admin/store/${currentItem._id}`, currentItem);
        */

        // with images
        const data = new FormData();
        data.append('storeid', this.state.storeid);
        //data.append('payment_methods', JSON.stringify(payment_methods));

        payment_methods.forEach(val => {
            data.append(`${'payment_methods'}[]`, val);
        });

        data.append('title', currentItem.title);
        data.append('address', currentItem.address);
        data.append('phone', currentItem.phone);
        data.append('description', currentItem.description);
        data.append('delivery_type', currentItem.delivery_type);
        data.append('delivery_price', currentItem.delivery_price);
        data.append('days', JSON.stringify(currentItem.days));
        

        data.append('logotipo', this.state.logotipo);
        data.append('cover_image', this.state.cover_image);
        

        await adminApi.put(`admin/store/${currentItem._id}`, data);
        
    }

    handleChange = e => {
		e.persist();
        this.setState(prevState => ({
            currentItem: { ...prevState.currentItem,  [e.target.id]: e.target.value }
        }));
    }

    showPaymentMethod = (payment, idx) => {
        let item = this.state.currentItem.payment_methods.find((element) => {
            return element === payment.code;
        })
        return <FormCheckbox id={payment.code} onChange={this.handlePaymentChange}
                    checked={this.state.currentItem.payment_methods.includes(payment.code)}
                    value={payment.code}>
                    {payment.title}
                </FormCheckbox>
    }

    handlePaymentChange = e => {
        let paymentMethods = this.state.paymentMethods;
        paymentMethods.map((item, idx) => {
            if(item.code === e.target.value){
                item.check = e.target.checked;
            }
        })
        this.setState({ paymentMethods })
    }
    
    // ---------------------------------------------------------------------------------------
    handleDaysChange = e => {
        let day = e.target.value;

        let item = this.state.currentItem;
        if(day === 'segunda'){
            item.days.segunda.open = (e.target.checked ? 1 : 0);
        } else if(day === 'terca'){
            item.days.terca.open = (e.target.checked ? 1 : 0);
        } else if(day === 'quarta'){
            item.days.quarta.open = (e.target.checked ? 1 : 0);
        } else if(day === 'quinta'){
            item.days.quinta.open = (e.target.checked ? 1 : 0);
        } else if(day === 'sexta'){
            item.days.sexta.open = (e.target.checked ? 1 : 0);
        } else if(day === 'sabado'){
            item.days.sabado.open = (e.target.checked ? 1 : 0);
        } else if(day === 'domingo'){
            item.days.domingo.open = (e.target.checked ? 1 : 0);
        }
        //console.log();
        this.setState( {currentItem: item});
    }

    handleHourChange = e => {
        let id = e.target.id;
        let value = e.target.value;

        let item = this.state.currentItem;

        if(id === 'domingo_de'){
            item.days.domingo.from = value;
        } else if(id === 'domingo_ate'){
            item.days.domingo.to = value;
        } else if(id === 'segunda_de'){
            item.days.segunda.from = value;
        } else if(id === 'segunda_ate'){
            item.days.segunda.to = value;
        } else if(id === 'terca_de'){
            item.days.terca.from = value;
        } else if(id === 'terca_ate'){
            item.days.terca.to = value;
        } else if(id === 'quarta_de'){
            item.days.quarta.from = value;
        } else if(id === 'quarta_ate'){
            item.days.quarta.to = value;
        } else if(id === 'quinta_de'){
            item.days.quinta.from = value;
        } else if(id === 'quinta_ate'){
            item.days.quinta.to = value;
        } else if(id === 'sexta_de'){
            item.days.sexta.from = value;
        } else if(id === 'sexta_ate'){
            item.days.sexta.to = value;
        } else if(id === 'sabado_de'){
            item.days.sabado.from = value;
        } else if(id === 'sabado_ate'){
            item.days.sabado.to = value;
        }

        this.setState( {currentItem: item});
    }

    // ---------------------------------------------------------------------------------------
    handleDeliveryChange = e => {
        const item = this.state.currentItem;
        item.delivery_type = e.target.value;

        if(e.target.value == "free"){
            item.delivery_price = '0';
        }

        this.setState( {currentItem: item});
    }
    handleDeliveryPriceChange = e => {
        const item = this.state.currentItem;
        item.delivery_price = e.target.value ;

        this.setState( {currentItem: item});
        /*
        this.setState(prevState => ({
            currentItem: { ...prevState.currentItem,  ['delivery_type']: e.target.value }
        }));
        */
    }

    handleLogotipoChange = e => {
        this.setState({
            logotipo_preview: URL.createObjectURL(e.target.files[0]),
            logotipo: e.target.files[0]
        });
    }
    handleCoverImageChange = e => {
        this.setState({
            cover_image_preview: URL.createObjectURL(e.target.files[0]),
            cover_image: e.target.files[0]
        });
    }    


    render(){
		const {
            currentItem,
            paymentMethods
        } = this.state;
        
        return (
            <Container fluid className="main-content-container px-6">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="12" title="Configurações da Loja" subtitle="" className="text-sm-left" />
            </Row>

            <Row>
            <Col>

                <ListGroup flush>
                    <ListGroupItem className="p-3">
                        <Row>
                        <Col>
                            <h5 sm="12" className="subtitle text-sm-left" >Dados básicos</h5>
                            <Form id='form'>

                            <Row form>
                                <Col md="6" className="form-group">
									<FormGroup>
										<label htmlFor="feInputAddress">Nome da Loja</label>
										<FormInput id="title" type="text" placeholder="Nome" value={currentItem.title}
											onChange={this.handleChange} />
                           			 </FormGroup>	
                                        								
									<FormGroup>
										<label htmlFor="feInputAddress">Endereço</label>
										<FormInput id="address" type="text" placeholder="Endereço" value={currentItem.address}
											onChange={this.handleChange} />
									</FormGroup>

                                </Col>
                                <Col md="6" className="form-group">
                          
									<FormGroup>
										<label htmlFor="feInputAddress">Endereço da Loja</label>
										<FormInput id="name" type="text"
											value={currentItem.name}
											disabled/>
									</FormGroup>

                                    <FormGroup>
                                        <label htmlFor="feInputAddress">Telefone</label>
                                        <FormInput id="phone" type="text" placeholder="Telefone" value={currentItem.phone}
                                            onChange={this.handleChange} />
                                    </FormGroup>

                                </Col>
                            </Row>

                            <FormGroup>
                                <label htmlFor="feInputAddress">Descrição</label>
                                <FormTextarea id="description" type="text" placeholder="Descrição"
                                    value={currentItem.description}
                                    onChange={this.handleChange} />
                            </FormGroup>

                            <br /><br />
                            <h5 sm="12" className="subtitle text-sm-left" >Horário de funcionamento</h5>

                            <Row>
                                <Col sm="3">
                                    Dia da semana
                                </Col> 
                                <Col sm="2">
                                    De
                                </Col>
                                <Col sm="2">
                                    Até
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='segunda'
                                    checked={currentItem.days.segunda.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Segunda-feira
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="segunda_de" type="text"
                                     value={currentItem.days.segunda.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.segunda.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="segunda_ate" type="text" 
                                     value={currentItem.days.segunda.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.segunda.open === 0}
                                     />
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='terca'
                                    checked={currentItem.days.terca.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Terça-feira
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="terca_de" type="text"
                                     value={currentItem.days.terca.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.terca.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="terca_ate" type="text" 
                                     value={currentItem.days.terca.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.terca.open === 0}
                                     />
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='quarta'
                                    checked={currentItem.days.quarta.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Quarta-feira
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="quarta_de" type="text"
                                     value={currentItem.days.quarta.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.quarta.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="quarta_ate" type="text" 
                                     value={currentItem.days.quarta.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.quarta.open === 0}
                                     />
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='quinta'
                                    checked={currentItem.days.quinta.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Quinta-feira
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="quinta_de" type="text"
                                     value={currentItem.days.quinta.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.quinta.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="quinta_ate" type="text" 
                                     value={currentItem.days.quinta.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.quinta.open === 0}
                                     />
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='sexta'
                                    checked={currentItem.days.sexta.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Sexta-feira
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="sexta_de" type="text"
                                     value={currentItem.days.sexta.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.sexta.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="sexta_ate" type="text" 
                                     value={currentItem.days.sexta.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.sexta.open === 0}
                                     />
                                </Col>                                        
                            </Row>
                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='sabado'
                                    checked={currentItem.days.sabado.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Sábado
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="sabado_de" type="text"
                                     value={currentItem.days.sabado.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.sabado.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="sabado_ate" type="text" 
                                     value={currentItem.days.sabado.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.sabado.open === 0}
                                     />
                                </Col>                                        
                            </Row>                                                                                                                                            

                            <Row>
                                <Col sm="3">
                                    <FormCheckbox value='domingo'
                                    checked={currentItem.days.domingo.open === 1}
                                    onChange={this.handleDaysChange}>
                                        Domingo
                                    </FormCheckbox>
                                </Col> 
                                <Col sm="2">
                                    <FormInput id="domingo_de" type="text"
                                     value={currentItem.days.domingo.from}
                                     onChange={this.handleHourChange}
                                     placeholder="12:00"
                                     disabled={currentItem.days.domingo.open === 0}
                                     />
                                </Col>
                                <Col sm="2">
                                    <FormInput id="domingo_ate" type="text" 
                                     value={currentItem.days.domingo.to}
                                     onChange={this.handleHourChange}
                                     placeholder="20:00" 
                                     disabled={currentItem.days.domingo.open === 0}
                                     />
                                </Col>                                        
                            </Row>

                            <br /><br />
                            <h5 sm="12" className="subtitle text-sm-left" >Formas de Pagamento e Taxa de Entrega</h5>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <FormGroup>
                                        <label htmlFor="active">Formas de Pagamento Aceitas</label>
                                        { /* paymentMethods.map((item, idx) => {
                                            return this.showPaymentMethod(item, idx);                       
                                        }) */}
                                        { this.state.paymentMethods.map((item, idx) => (
                                            <FormCheckbox id={item.code} onChange={e => this.handlePaymentChange(e, item)}
                                                checked={item.check == true}
                                                value={item.code}>
                                                {item.title}
                                            </FormCheckbox>
                                        ))}
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="form-group">
                                    <FormGroup>
                                        <label htmlFor="active">Taxa de entrega</label>
                                        <FormRadio name="delivery_type" onChange={this.handleDeliveryChange}
                                                checked={currentItem.delivery_type === "free"}
                                                value="free">
                                                Entrega grátis
                                        </FormRadio>
                                        <FormRadio name="delivery_type" onChange={this.handleDeliveryChange}
                                                checked={currentItem.delivery_type !== "free"}
                                                value="pay">
                                                Valor definido
                                        </FormRadio>
                                        <FormGroup>
                                            <label htmlFor="feInputAddress">Valor da entrega</label>
                                            <FormInput id="delivery_price" type="text"
                                                onChange={this.handleDeliveryPriceChange}
                                                value={currentItem.delivery_price}
                                                disabled={currentItem.delivery_type === "free"}/>
                                        </FormGroup>
                                      
                                    </FormGroup>
                                </Col>                                
                            </Row>

                            <br /><br />
                            <h5 sm="12" className="subtitle text-sm-left" >Logotipo e imagem de capa</h5>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <FormGroup>
                                        <label htmlFor="feInputAddress">Logotipo</label>
                                        <FormInput id="logotipo" type="file"
                                            onChange={this.handleLogotipoChange}  />
                                    </FormGroup>
                                    { this.state.logotipo_preview && <img class="logotipoPreview" src={this.state.logotipo_preview}/> }
                                    { !this.state.logotipo_preview && currentItem.logotipo &&
                                         <img class="logotipoPreview" src={`http://localhost:3333/files/${currentItem._id}/${currentItem.logotipo}`}/> }
                                </Col>
                                <Col md="6" className="form-group">
                                    <FormGroup>
                                        <label htmlFor="feInputAddress">Imagem de Capa</label>
                                        <FormInput id="cover_image" type="file"
                                            onChange={this.handleCoverImageChange}  />
                                    </FormGroup>
                                    { this.state.cover_image_preview && <img className="coverImagePreview" src={this.state.cover_image_preview}/> }                              
                                    { !this.state.cover_image_preview && currentItem.cover_image &&
                                         <img class="logotipoPreview" src={`http://localhost:3333/files/${currentItem._id}/${currentItem.cover_image}`}/> }                                    
                                </Col>
                            </Row>

                            <Button type="button" className="btAction" onClick={this.saveHandler}>Salvar Configurações</Button>
                            </Form>
                        </Col>
                        </Row>
                    </ListGroupItem>
                    </ListGroup>

                </Col>
            </Row>
            </Container>
        );
    }
}

export default Configuration;
