import React, { Component } from 'react'
import { Row, Col, ModalFooter } from "shards-react";

import {
	Form,
	FormInput,
	FormTextarea,
	Button,
	Modal, ModalBody, ModalHeader
  } from "shards-react";
import './ModalProduct.css';

export default class ModalProduct extends Component {

	constructor(props) {
    super(props);

    this.emptyItem = {
      _id: '',
      name: '',
      price: '',
      qtd: '1',
      subtotal: '',
      comments: '',
    }

    this.state = {
      currentItem: props.currentItem,	
      openModal: props.openModal
    };

    this.setState({ currentItem: props.currentItem });
	}


  render() {
    const { modalOpen, addToCartHandler, closeModalHandler, currentItem,
            handleChange, handleLess, handleMore } = this.props;

    return (
      <Modal animationIn="slideInUp" animationOut="slideOutDown" open={modalOpen} toggle={closeModalHandler} id="modal">
        { /*
        <Row md="12">
          <Col md='10'>
            <span className="modal-title">
              <span>Adicionar ao carrinho</span>
            </span>
          </Col>
          <Col md='2'>
            <span className="close-modal-btn" onClick={closeModalHandler}>×</span>
          </Col>
        </Row>
        */ }

        <ModalBody>
          <Form id='addToCartModal'>
            <Row>
              <Col md="12" className="form-group">
                <div className="title">{currentItem.name}</div>
                <div className="description">{currentItem.description}</div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="form-group">
                <div className="comments">Observações</div>
                <FormTextarea id="comments" type="text" placeholder="Exemplo: retirar cebola / sem queijo / sem pimenta"
                  value={currentItem.comments}
                  onChange={handleChange} />
              </Col>
            </Row>
            <Row>
              <Col md="12" className="form-group">
                <div className="quantity">Quantidade</div>

                <div className='quantity-field'>
                  <FormInput className="qtdField" id="qtd" type="number" value={currentItem.qtd} onChange={handleChange} />      

                  <div className="qtd-buttons">
                    <div className='qtd-buttom-less' onClick={handleLess}>-</div>
                    <div className='qtd-buttom-more' onClick={handleMore}>+</div>
                  </div>

                </div>
              </Col>
            </Row>            
            
            { /*
            <Row>
              <Col>Valor: {currentItem.price}</Col>
              <Col>Subtotal: {currentItem.subtotal}</Col>
            </Row>
            */ }

          </Form>
        </ModalBody>

        <ModalFooter>
          <div class="modal-footer-price">
            <div className='modal-price'>
              { parseFloat(currentItem.subtotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            </div>  
            <Button className="btAddToCart" type="button" onClick={() => addToCartHandler(currentItem)}>Adicionar ao carrinho</Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}


                