
import React from 'react';
import { Redirect } from "react-router-dom";

import { adminService } from '../../services/admin.service';

class AdminLogout extends React.Component {
    constructor(props) {
        super(props);
        adminService.logout();
    }

    render() {
        return (
            <Redirect to="/admin/login" />
        );
    }
}

export default AdminLogout;
