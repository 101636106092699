
import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header/Header';
import SubHeader from '../../components/subheader/SubHeader';
import config from '../../config/site.config.js';
import { userService } from '../../services/user.service';
import { storeService } from '../../services/store.service';
import auth from '../../_helpers/auth';
import "./Login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      submitted: false,
      loading: false,
      error: '',
      subdomain: null,
      store: { title: ''},
      redirect: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount(){
		let subdomain = await this.getStoreName();
		this.setState({subdomain});
		await this.loadStoreData(this.state.subdomain);
	}

	async getStoreName(){
		var uris2 = window.location.hostname.split('.');
		return uris2[0];
	}

	async loadStoreData(storename){
		storeService.getStore(storename)
			.then(
				store => {
					this.setState({ store: store });
				},
				error => console.log(error)
			);
		this.setState({ loading: false });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    //const { email, password, returnUrl } = this.state;
    const { email, password } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.setState({ loading: true });
		

		/* nova tentativa de fazer o usuario atualizar depois de logado
		let logged = await auth.signIn(email, password, this.state.subdomain);
		console.log("logged");
		console.log(logged);

		if(logged){
			this.setState({ redirect: true });
		} else {
			this.setState({ error: 'E-mail ou senha inválidos', loading: false });
		}
		this.setState({ loading: false });
		*/

		
		//forma antiga de fazer o login 
    await userService.login(email, password, this.state.subdomain)
      .then(
        user => {
          auth.setUser(user);
          this.setState({ redirect: true });
        },
          error => this.setState({ error, loading: false })
      );
    this.setState({ loading: false });
  }

  render() {
		const { email, password, submitted, loading, error, redirect, store } = this.state;
		if (redirect) {
			return <Redirect to="/home" />;
		}
    return (
			<div id="login">

        <Header store={this.state.store} 
          logotipo={`${config.urlFiles}/${store._id}/${store.logotipo}`}
        />
        <SubHeader title="Login" />

        <div className="login-form">
          <div className="h-100 no-gutters row">
            <div className="auth-form mx-auto my-auto col-md-5 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="auth-form__title text-center mb-4">Acesse sua conta</h5>
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                      <label htmlFor="email">E-mail</label>
                      <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                      {submitted && !email &&
                        <div className="help-block">E-mail é obrigatório</div>
                      }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                      <label htmlFor="password">Senha</label>
                      <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                      {submitted && !password &&
                        <div className="help-block">Senha é obrigatório</div>
                      }
                    </div>
                    { /*
                    <div className="form-group">
                      <label className="custom-control custom-checkbox">
                        <input id="dr-checkbox-giXl7BqkI" type="checkbox" className="custom-control-input" />
                        <label id="dr-checkbox-giXl7BqkI" className="custom-control-label" aria-hidden="true"></label>
                        <span className="custom-control-description">Lembrar-me por 30 dias.</span>
                      </label>
                    </div>
                    */ }
                      <div className="loading">
                          { loading &&
                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          }
                      </div>
                      {error &&
                          <div className={'alert alert-danger'}>{error}</div>
                      }
                    <button className="d-table mx-auto btn btn-accent btn-pill" disabled={loading}>Acessar sua conta</button>
                  </form>
                </div>
                { /*
                <div className="card-footer">
                  <ul className="auth-form__social-icons d-table mx-auto">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-github"></i></a></li>
                    <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                  </ul>
                </div>
                */ }
              </div>
              <div className="auth-form__meta d-flex mt-4">
                <a href="/forgotpassword">Esqueceu a senha?</a>
                <a className="ml-auto" href="/register">Criar uma nova conta?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
